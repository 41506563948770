<template>
  <div>
    <div class="d-flex align-center justify-end">
      <botao-padrao
        class="mt-3"
        @click="buscaServicosManutencao"
      >
        <v-icon>$mdiRefresh</v-icon>
        {{ $t('modulos.contrato.atualizar_dados') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="form.contratoServicoManutencao"
      item-key="chaveComposta"
      class="mt-2"
      :colunas="tabela.colunas"
      filtro-geral
      paginacao-em-memoria
      sem-paginacao
      :filters="filters"
      ajustar-altura-linha
      ajustar-dropdown-acima
      :mostrar-acoes="false"
      :mostrar-seletor="false"
      :por-pagina="tabela.porPagina"
      :pagina-atual="tabela.paginaAtual"
      :global-filters="tabela.colunas.map(c => c.value)"
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import { FilterMatchMode } from 'primevue/api';
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    instrumentoString: { type: String, default: '' },
  },
  
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      sequencia: 1,
      itensSelecionados: [],
      tabela: {
        selecionados: [],
        dados: [],
        porPagina: 10,
        paginaAtual: 1,
        colunas: [
          {
            value: 'servicoCodigo',
            text: this.$t('modulos.contrato.tabela.codigo'),
            sortable: true,
            width: '10%',
          },
          {
            value: 'servicoDescricao',
            text: this.$t('modulos.contrato.tabela.descricao'),
            sortable: true,
            width: '45%',
          },
          {
            value: 'tipoInstrumentoNome',
            text: this.$t('modulos.contrato.tabela.tipo_instrumento'),
            sortable: true,
          },
          {
            value: 'modeloInstrumentoNome',
            text: this.$t('modulos.contrato.tabela.modelo_instrumento'),
            sortable: true,
          },
          {
            value: 'servicoValor',
            text: this.$t('modulos.contrato.tabela.valor'),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  watch: {
    instrumentoString() {
      this.buscaServicosManutencao();
    },
  },
  mounted() {
    if (!this.form.id) this.buscaServicosManutencao();
  },
  methods: {
    buscaServicosManutencao() {
      // if (!this.instrumentoString) return;
      // this.form.contratoServicoManutencao = [];
      // this.$store.dispatch('Layout/iniciarCarregamento');
      // InstrumentoService.listarTipoServicosManutencaoPecas(
      //   this.instrumentoString
      // )
      //   .then(async (res) => {
      //     if (res.data.servicoManutencao.length) {
      //       res.data.servicoManutencao.map((item) => {
      //         this.form.contratoServicoManutencao.push({
      //           tipoInstrumentoId: item.tipoInstrumentoId,
      //           modeloInstrumentoId: item.modeloInstrumentoId,
      //           servicoId: item.servicoId,
      //           servicoDescricao: item.servicoDescricao,
      //           valor: item.servicoValor,
      //           servicoCodigo: item.servicoCodigo,
      //           tipoInstrumentoNome: item.tipoInstrumentoNome,
      //           modeloInstrumentoNome: item.modeloInstrumentoNome,
      //           servicoValor: item.servicoValor,
      //         });
      //       });
      //     }
      //   })
      //   .finally(() => {
      //     this.$store.dispatch('Layout/terminarCarregamento');
      //   });
    },
  },
};
</script>
