<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div />
      <div class="d-flex">
        <botao-padrao
          v-if="!$route.query.visualizacao"
          class="mt-3 mb-3"
          :disabled="!form.tipoContrato"
          :tooltip="form.tipoContrato ? '' : $t('modulos.contrato.selecionar_tipo_contrato')"
          @click="abreModalClausulaContratual"
        >
          {{
            $t(
              'modulos.contrato.formulario.clausula_contratual.associar_desassociar_clausula_contratual'
            )
          }}
        </botao-padrao>
      </div>
    </div>
    <div class="d-flex align-center mb-1">
      <botao-padrao
        v-if="podeExcluir"
        color="secondary"
        outlined
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('cima')"
      >
        <v-icon> $arrowUp </v-icon>
      </botao-padrao>

      <botao-padrao
        v-if="podeExcluir"
        color="secondary"
        outlined
        class="mx-2"
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('baixo')"
      >
        <v-icon> $arrowDown </v-icon>
      </botao-padrao>
      <botao-padrao
        v-if="podeExcluir"
        class="my-2 ml-2"
        outlined
        color="danger"
        @click="excluir"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>
    <v-data-table
      v-model="tabela.selecionados"
      :headers="tabela.colunas"
      :items="form.contratoClausulasContratuais"
      item-key="id"
      dense
      hide-default-footer
      :show-select="!$route.query.visualizacao"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.acoes="{ item }">
        <div class="d-flex">
          <div>
            <dropdown-padrao
              text
              color="secondary"
              style="width: 100% !important"
            >
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>

              <v-list-item-group style="overflow-y: auto">
                <v-list-item
                  class="d-flex min-height-drop-item"
                  :disabled="$route.query.visualizacao"
                  @click="abrirModalEditar(item)"
                >
                  {{ $t('geral.botoes.editar') }}
                </v-list-item>
              </v-list-item-group>
            </dropdown-padrao>
          </div>
        </div>
      </template>
      <template v-slot:item.sequencia="item">
        {{ item.index + 1 }}
      </template>
    </v-data-table>
    <ClausulaContratualModal
      ref="modal-clausula-contratual"
      :form="form"
      @novoArquivo="novaClausulaContratual"
    />
    <FormularioClausulaContratual
      ref="modal-form-clausula-contratual"
      :form="form"
      @novoArquivo="editarClausulaContratual"
    />
  </div>
</template>
<script>
import ClausulaContratualModal from './modais/ClausulaContratualModal.vue';
import FormularioClausulaContratual from './modais/FormularioClausulaContratual.vue';
export default {
  components: {
    ClausulaContratualModal,
    FormularioClausulaContratual
  },
  props: {
    form: {},
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            sortable: false,
            width: 1,
          },
          {
            value: 'sequencia',
            text: this.$t('modulos.contrato.formulario.clausula_contratual.sequencia'),
            sortable: false,
            width: 100,
          },
          {
            value: 'titulo',
            text: this.$t('modulos.contrato.formulario.clausula_contratual.nome'),
            sortable: true,
            width: 1500,
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  methods: {
    abreModalClausulaContratual() {
      this.$refs['modal-clausula-contratual'].abrirModal();
    },
    abrirModalEditar(item) {
      this.$refs['modal-form-clausula-contratual'].abrirModal(item);
    },
    excluir() {
      this.form.contratoClausulasContratuais = this.form.contratoClausulasContratuais.filter(obj => obj.id != this.tabela.selecionados.map(item => item.id))
    },
    novaClausulaContratual(itens){
      this.form.contratoClausulasContratuais = itens
    },
    editarClausulaContratual(clausulaAtualizada){
      this.form.contratoClausulasContratuais.push(clausulaAtualizada)
      this.form.contratoClausulasContratuais = Object.values(this.form.contratoClausulasContratuais.reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {}));
    },
    movimentarItem: function (direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let itensSelecionados = [...this.tabela.selecionados]
      let itensSelecionadosComIndex = itensSelecionados.map(item => {
        return {
          ...item, index: this.form.contratoClausulasContratuais.indexOf(item)
        }
      })

      itensSelecionadosComIndex.sort((a, b) => incremento === -1 ? a.index - b.index : b.index - a.index)

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.form.contratoClausulasContratuais]
        const novoIndex = item.index + incremento

        if (novoIndex < 0 || novoIndex >= novaLista.length) {
          return
        }
        const itemListaAntes = novaLista[item.index + incremento]

        novaLista[item.index + incremento] = novaLista[item.index]
        novaLista[item.index] = itemListaAntes
        this.form.contratoClausulasContratuais = [...novaLista]
      });
    },
  },
};
</script>
