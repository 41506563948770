
export class EnumTagsClasulaContratual {
  constructor(lista) {
    if (Array.isArray(lista))
      return lista.map((el) => {
        let novoObj = {};
        novoObj.titulo = el.value;
        novoObj.text = el.text;
        novoObj.seq = el.seq
        return novoObj;
      });
    return {
      value: lista.value,
      text: lista.text,
      seq: lista.seq,
    };
  }
}
