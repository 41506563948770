<template>
  <div>
    <div class="d-flex mb-8">
      <div>
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
        <div v-if="form.codigo">
          <v-chip
            class="mr-1"
            small
          >
            Código: {{ `${form.codigo}` }}
          </v-chip>
        </div>
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.contrato.formulario.abas.dados_contrato') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.contrato.formulario.abas.escopo_contrato') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.contrato.formulario.abas.clausula_contratual') }}
      </v-tab>
      <v-tab :disabled="!id">
        {{ $t('modulos.contrato.formulario.abas.comentarios') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-contrato
          ref="dados-contrato"
          :form="form"
          :primeira-vez="primeiraVez"
        />
      </v-tab-item>
      <v-tab-item>
        <escopo-contrato :form="form" />
      </v-tab-item>
      <v-tab-item>
        <clausula-contratual :form="form" />
      </v-tab-item>
      <v-tab-item>
        <comentario-contrato
          :form="form"
          :permissoes="permissoes"
          origen="contrato"
          :visualizacao="$route.query.visualizacao"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-12">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="!$route.query.visualizacao"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ContratoService from '@common/services/cadastros/ContratoService';
import DadosContrato from './components/DadosContrato';
import EscopoContrato from './components/EscopoContrato';
import ClausulaContratual from './components/ClausulaContratual';
import ComentarioContrato from '../../../../components/comentario/Comentario.vue';
import { ContratoModel } from '@common/models/cadastros/ContratoModel';
import helpers from '@common/utils/helpers';
export default {
  components: {
    DadosContrato,
    EscopoContrato,
    ClausulaContratual,
    ComentarioContrato,
  },
  props: ['id'],
  data() {
    return {
      permissoes: {
        funcionalidade: 'Contrato',
        acaoComentar: 'Comentar',
        acaoResponder: 'ResponderComentario',
        acaoEncerrar: 'EncerrarComentario',
        acaoExcluir: 'ExcluirComentario',
      },
      dadosRequisitosClienteFormatado: null,
      validacaoItensContrato: false,
      usuarioId: null,
      valorItens: null,
      valorFinal: 0,
      valido: false,
      prazoEntregaPadrao: null,
      prazoGarantia: null,
      indiceAba: 0,
      form: new ContratoModel({
        contratoInstrumento: [],
      }),
      watchs: {
        dataInicio: null,
        parcelas: null
      },
      primeiraVez: true,
    };
  },
  computed: {
    tituloFormulario: function () {
      if(this.$route.query.visualizacao) return this.$t('modulos.contrato.titulos.visualizar');
      if (this.id) return this.$t('modulos.contrato.titulos.editar');
      return this.$t('modulos.contrato.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      if (this.form.contratoInstrumento.length == 0)
        return this.$t('modulos.contrato.validacoes.escopo_vazio');
      return this.$t('modulos.contrato.validacoes.formulario_invalido');
    },
  },
  watch: {
    'form.politicaPrecoPeca'(value, oldValue) {
      if (value && oldValue && this.form.contratoPeca.length) {
        this.toastAlerta(
          this.$t(
            `modulos.contrato.validacoes.troca_politica_preco_peca_alerta`
          )
        );

        if (value == 'Valor') {
          this.form.contratoPeca.map((item) => {
            item.percentualDesconto = 0;
            item.valorVendaComDesconto = item.valorVenda;
          });
        }
      }
    },
  },
  mounted() {
    this.form.participanteContaId = this.$store.getters['Autenticacao/participanteSelecionado'];
    this.usuarioId = this.$store.getters['Autenticacao/usuario']?.id;
    this.limparWatchers();
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Contrato', 'Editar');
      this.buscar(this.id);
    } else {
      this.primeiraVez = false;
      this.setarWatchers();
      this.form.contratoHistoricoModificacao = [
        {
          usuarioId: this.usuarioId,
          dataHora: new Date(),
          ocorrencia: 'Contrato Criado',
        },
      ];
    }
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    helpers.redirecionarSemPermissao(this, 'Contrato', 'Inserir');

  },
  methods: {
    limparWatchers() {
      if (this.watchs.dataInicio) this.watchs.dataInicio();
      if (this.watchs.parcelas) this.watchs.parcelas();
    },
    setarWatchers(){
      this.watchs.dataInicio = this.$watch('form.dataInicio', (value, oldValue) => {
        if (
          value &&
          oldValue &&
          value.substring(0, 10) !== oldValue.substring(0, 10)
        ) {
          this.calculaCalibracoes();
        }
      });
      this.watchs.parcelas = this.$watch('form.numeroParcela', (value, oldValue) => {
        if (value) this.calculaValoresContrato();
        if (value && oldValue && value !== oldValue) {
          this.calculaCalibracoes();
        }
      });
    },
    calculaValoresContrato() {
      this.valorFinal = 0;
      this.form.contratoInstrumento.map((item) => {
        if (item.contratoInstrumentoServicoCalibracao?.length) {
          item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
            this.valorFinal += itemDetalhe.valorTotalCalibracao;
          });
        }
      });
      this.form.valorFinal = this.valorFinal;

      if (this.form.valorFinal && this.form.numeroParcela) {
        this.form.valorMensal = (
          this.form.valorFinal / this.form.numeroParcela
        ).toFixed(2);
      }
      this.valorItens = 0;
      this.form.contratoInstrumento.map((item) => {
        item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
          this.valorItens += itemDetalhe.valorTotalCalibracao;
        });
      });

      if (this.valorItens)
        this.form.valorItens = Number(this.valorItens).toFixed(2);
    },
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.buscar(this.id)
        .then(async (res) => {
          this.form = new ContratoModel(res.data);
          this.form.participante = res.data.participante?.nome;
          this.form.participanteId = res.data.participante?.id;
          this.form.participanteContatoId = res.data.participanteContato?.id;
          this.form.participanteContaId = localStorage.getItem(
            'participanteLogadoId'
          );
          this.form.valorFinal = res.data.valorFinal;

          this.form.contratoServicoManutencao = [];
          if (res.data.contratoServicoManutencao.length) {
            res.data.contratoServicoManutencao.map((item) => {
              this.form.contratoServicoManutencao.push({
                tipoInstrumentoId: item.tipoInstrumento.id,
                modeloInstrumentoId: item.modeloInstrumento?.id,
                servicoId: item.servico.id,
                servicoDescricao: item.servicoDescricao,
                valor: item.valor,
                tipoInstrumentoNome: item.tipoInstrumento.nome,
                modeloInstrumentoNome: item.modeloInstrumento?.nome,
                servicoValor: item.valor,

                servicoCodigo: item.codigoServico,
              });
            });
          }

          this.form.contratoPeca = [];
          if (res.data.contratoPeca.length) {
            res.data.contratoPeca.map((item) => {
              this.form.contratoPeca.push({
                id: item.id,
                percentualDesconto: item.percentualDesconto,
                tipoInstrumentoId: item.tipoInstrumento.id,
                modeloInstrumentoId: item.modeloInstrumento?.id,
                itemId: item.item.id,
                itemDescricao: item.itemDescricao,
                tipoInstrumentoNome: item.tipoInstrumento.nome,
                modeloInstrumentoNome: item.modeloInstrumento?.nome,
                valorVenda: item.valor,
                valorVendaComDesconto: item.valorComDesconto,

                itemCodigo: item.item.codigo,
              });
            });
          }

          this.form.contratoInstrumento.map((item) => {
            item.codigo = item.instrumento?.codigo;
            item.instrumentoId = item.instrumento?.id;
            item.tipoCalibracaoId = item.tipoCalibracao?.id;
            item.tipoCalibracaoNome = item.tipoCalibracao?.nome;
            item.localExecucaoId = item.localExecucao?.id;
            item.localExecucaoNome = item.localExecucao?.nome;
          });

          this.form.contratoInstrumento.forEach((item) => {
            if (item.contratoInstrumentoServicoCalibracao?.length) {
              item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
                itemDetalhe.servicoId = itemDetalhe.servico?.id;
                itemDetalhe.faixa = itemDetalhe.servico?.faixa
                itemDetalhe.servicoCodigo = itemDetalhe.servico?.codigo;
                itemDetalhe.valorServico = itemDetalhe.valorUnitarioServico;
              });
            }
          });
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.orcamentos.erros.contrato_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = true;
          this.primeiraVez = false;
          this.setarWatchers();
        });
    },
    confirmacaoSalvar: function () {
      if (!this.validacaoCamposItensContrato()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    ordenarItemDetalhes(itemDetalhes) {
      itemDetalhes?.sort((a, b) => {
        if (a.tipoServico === null && b.tipoServico === null) return 0;
        if (a.tipoServico === null) return 1;
        if (b.tipoServico === null) return -1;
        return a.tipoServico - b.tipoServico;
      });
    },
    validacaoCamposItensContrato() {
      this.validacaoItensContrato = true;
      this.form.contratoInstrumento.map((item) => {
        if (!item.prazoEntrega) {
          this.validacaoItensContrato = false;
          this.toastAlerta(this.$t(`geral.erros.prazo_entrega_preencher`));
        }
        if (!item.contratoInstrumentoServicoCalibracao.length) {
          this.validacaoItensContrato = false;
          this.toastAlerta(this.$t(`geral.erros.insira_servico_peca`));
        } else {
          item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
            if (!itemDetalhe.dataUltimaCalibracao) {
              this.validacaoItensContrato = false;
              this.toastAlerta(
                this.$t(`geral.erros.data_ultima_calibracao_preencher`)
              );
            }
            if (!itemDetalhe.valorUnitarioServico) {
              this.validacaoItensContrato = false;
              this.toastAlerta(this.$t(`geral.erros.valor_unitario_preencher`));
            }
          });
        }
      });

      return this.validacaoItensContrato;
    },
    salvar: function () {
      this.calculaValoresContrato();
      if (this.$refs['dados-contrato']) {
        this.valido = this.$refs['dados-contrato'].$refs.form.validate();
      }

      if (!this.valido) {
        this.indiceAba = 0;
        return;
      }

      if (this.form.contratoInstrumento.length == 0) {
        this.valido = false;
        this.toastAlerta(this.$t(`geral.erros.insira_escopo_contrato`));
        return;
      }
      let calibracaoQuantidadeInvalida = false;
      this.form.contratoInstrumento.map((item) => {
        item.flagAdicionadoCliente = false;
        if (item.contratoInstrumentoServicoCalibracao.length) {
          item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {

            itemDetalhe.flagRemovidoCliente = false;
            if(!itemDetalhe.quantidadeTotalCalibracao) calibracaoQuantidadeInvalida = true;
            if (itemDetalhe.pontosCalibrar)
              itemDetalhe.pontosCalibrar = parseInt(itemDetalhe.pontosCalibrar);
          });
        }
      });
      if(calibracaoQuantidadeInvalida) return this.toastAlerta(this.$t(`modulos.contrato.validacoes.calibracoes_zerada`));
      
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.contrato.cadastro_sucesso`));
          this.$router.push({ name: 'contrato' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'contrato' });
      });
    },
    calculaCalibracoes() {
      if (!this.form.contratoInstrumento.length) return;
        this.form.contratoInstrumento.forEach((item) => {
          if (!item.contratoInstrumentoServicoCalibracao.length) return;
          item.contratoInstrumentoServicoCalibracao?.forEach((itemDetalhe) => {
            if (!this.form.dataInicio && !this.form.numeroParcela) {
              this.toastAlerta(this.$t(`geral.erros.preencha_data_parcelas`));
              return;
            }
            if (!itemDetalhe.dataUltimaCalibracao) {
              this.toastAlerta(
                this.$t(`geral.erros.preencha_frequencia_data`)
              );
              return;
            }
            let params = {
              inicioContrato: this.form.dataInicio,
              numeroParcelas: this.form.numeroParcela,
              ultimaCalibracao: itemDetalhe.dataUltimaCalibracao,
              frequenciaMeses: item.frequenciaCalibracaoEmMeses,
            };

            this.$store.dispatch('Layout/iniciarCarregamento');
            ContratoService.buscarDatasProximasCalibracoes(params)
              .then((res) => {
                  if(!res.data.quantidadeCalibracoes) this.toastAlerta(this.$t('modulos.contrato.validacoes.calibracoes_zerada'))
                  itemDetalhe.quantidadeTotalCalibracao = res.data.quantidadeCalibracoes;
                if (res.data?.proximasCalibracoes.length) {
                  res.data?.proximasCalibracoes.map(
                    (itemProximaCalibracao) => {
                      itemDetalhe.contratoInstrumentoServicoCalibracaoProximaCalibracao =
                        [];
                      itemDetalhe.contratoInstrumentoServicoCalibracaoProximaCalibracao.push(
                        {
                          dataProximaCalibracao: `${itemProximaCalibracao}`,
                        }
                      );
                    }
                  );
                }
              })
              .catch(() => {
                this.toastErro(
                  this.$t(
                    'modulos.contrato.erros.erro_buscar_datas_calibracao'
                  )
                );
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          });
      });
    },
  },
};
</script>
