<template>
  <div>
    <v-tabs
      v-model="indiceAba"
      class="mt-2"
      background-color="white"
      color="black"
    >
      <v-tab color="black">
        {{ $t('modulos.contrato.formulario.abas.servicos_calibracao') }}
      </v-tab>
      <v-tab color="green">
        {{ $t('modulos.contrato.formulario.abas.servicos_manutencao') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.contrato.formulario.abas.pecas') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <escopo-calibracao
          ref="escopo-calibracao"
          :form="form"
          @adicionaServicosPecasInstrumento="adicionaServicosPecasInstrumento"
        />
      </v-tab-item>
      <v-tab-item>
        <escopo-manutencao
          :form="form"
          :instrumento-string="instrumentoString"
        />
      </v-tab-item>
      <v-tab-item>
        <escopo-peca :form="form" :instrumento-string="instrumentoString" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import EscopoCalibracao from './EscopoCalibracao';
import EscopoManutencao from './EscopoManutencao';
import EscopoPeca from './EscopoPeca';
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
export default {
  components: { EscopoCalibracao, EscopoManutencao, EscopoPeca },
  props: {
    form: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      indiceAba: 0,
      instrumentoString: '',
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    adicionaServicosPecasInstrumento(instrumentosSelecionadosIds) {
      this.montaStringInstrumentosIdsServicosManutencao(
        instrumentosSelecionadosIds
      );
    },
    montaStringInstrumentosIdsServicosManutencao(instrumentosSelecionadosIds) {
      this.instrumentoString = '';
      if (instrumentosSelecionadosIds.length) {
        instrumentosSelecionadosIds.map((item) => {
          this.instrumentoString += `&instrumentoId=${item}`;
        });
      }

      this.buscaServicosManutencaoPeca();
    },
    buscaServicosManutencaoPeca() {
      if (!this.instrumentoString) return;
      this.form.contratoServicoManutencao = [];
      this.form.contratoPeca = [];
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.listarTipoServicosManutencaoPecas(
        this.instrumentoString
      )
        .then(async (res) => {
          if (res.data.servicoManutencao.length) {
            res.data.servicoManutencao.map((item) => {
              this.form.contratoServicoManutencao.push({
                tipoInstrumentoId: item.tipoInstrumentoId,
                modeloInstrumentoId: item.modeloInstrumentoId,
                servicoId: item.servicoId,
                servicoDescricao: item.servicoDescricao,
                valor: item.servicoValor,
                servicoCodigo: item.servicoCodigo,
                tipoInstrumentoNome: item.tipoInstrumentoNome,
                modeloInstrumentoNome: item.modeloInstrumentoNome,
                servicoValor: item.servicoValor,
              });
            });
          }
          if (res.data.peca.length) {
            res.data.peca.map((item) => {
              this.form.contratoPeca.push({
                tipoInstrumentoId: item.tipoInstrumentoId,
                modeloInstrumentoId: item.modeloInstrumentoId,
                itemId: item.itemId,
                id: item.itemId,
                percentualDesconto: 0,
                itemDescricao: item.itemDescricao,
                itemCodigo: item.itemCodigo,
                tipoInstrumentoNome: item.tipoInstrumentoNome,
                modeloInstrumentoNome: item.modeloInstrumentoNome,
                valorVenda: item.itemValor,
                valorVendaComDesconto: item.itemValor,
              });
            });
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
