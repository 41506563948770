export class ClausulaContratualModel {
  constructor({
    id,
    titulo,
    sequencia,
    classificacao,
    descricao,
  }) {
    this.id = id;
    this.sequencia = sequencia;
    this.titulo = titulo;
    this.classificacao = classificacao;
    this.descricao = descricao;
  }
}
