<template>
  <modal-padrao
    ref="modal"
    max-width="80%"
    :ok-desabilitado="!valido"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo="$t('modulos.contrato.formulario.comentarios.novo_comentario')"
    @ok="confirmacaoSalvar"
  >
    <div class="container">
      <v-form ref="form" class="row">
        <input-textarea
          v-model="form.mensagem"
          class="col-12 col-md-12"
          :label="$t('modulos.contrato.formulario.comentarios.comentario')"
          obrigatorio
        />
        <div class="mb-3 d-inline-flex">
          <input-checkbox
            v-if="flagParticipanteConta"
            v-model="form.flagPrivado"
            class="d-inline ml-2"
            :label="$t('modulos.contrato.formulario.comentarios.privado')"
          />
          <input-checkbox
            v-model="form.status"
            class="d-inline ml-4"
            :label="$t('modulos.contrato.formulario.comentarios.encerrar')"
          />
          <input-checkbox
            v-if="flagParticipanteConta"
            v-model="form.flagNotificar"
            class="d-inline ml-4"
            :label="$t('modulos.contrato.formulario.comentarios.notificar')"
          />
        </div>
      </v-form>
    </div>
  </modal-padrao>
</template>
<script>
import UsuarioService from '@common/services/cadastros/UsuarioService';
export default {
  props: {
    flagParticipanteConta: { type: Boolean, default: true },
  },
  data() {
    return {
      valido: null,
      edicao: false,
      form: {},
      formReferencia: {},
      usuarioId: null,
      id: null,
      tipo: null,
    };
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  methods: {
    iniciarForm: function () {
      this.form = {
        orcamentoId: null,
        contratoId: null,
        tipoComentario: null,
        mensagem: null,
        flagPrivado: false,
        flagNotificar: false,
        status: false,
        comentarioProprietarioId: null,
      };
    },
    abrirModal: function (id, tipo) {
      this.id = id;
      this.tipo = tipo;

      this.iniciarForm();

      this.$refs['modal'].abrirModal();
      if (this.$refs.form) this.valido = this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;

      this.buscarUsuario(localStorage.getItem('usuarioId'));

      if (this.tipo === 'resposta')
        this.form.comentarioProprietarioId = this.id;

      // TODO Adicionar verificação quando a informação for incluída no token
      this.form.tipoComentario = '2';

      // se for cliente (não participante) deve notificar por padrão
      if (!this.flagParticipanteConta) {
        this.form.flagNotificar = true;
      }

      if (!this.edicao) this.$emit('salvar-novo', this.form);
      if (this.edicao) {
        Object.entries(this.form).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal'].fecharModal();
    },
    confirmacaoSalvar: function () {
      if (this.edicao) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    buscarUsuario: function (id) {
      UsuarioService.buscar(id).then((res) => {
        this.form.usuarioCriacaoId = res.data.id;
      });
    },
  },
};
</script>
<style scoped>
.corpo-modal {
  overflow: hidden;
}
</style>
