<template lang="">
  <modal-padrao
    ref="modal-edicao-instrumento"
    max-width="90%"
    :titulo="$t('modulos.ordem_servico.formulario.instrumento.instrumento')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :mostrar-botoes="false"
  >
    <formulario
      :mostra-titulo="false"
      :formulario-modal="true"
      @fecha-modal-criacao-intrumento="fecharModal"
      @atualiza-instrumento="atualizaInstrumento"
      :id="instrumentoId"
    />
  </modal-padrao>
</template>
<script>
import formulario from '@views/modulos/cadastros/instrumento/formulario';
export default {
  components: { formulario },
  props: {
    instrumentoId: { type: String, default: '' },
    multiplos: { type: Boolean, default: true },
    atualiza: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
  },
  methods: {
    atualizaInstrumento(instrumentoEditado) {
      if (this.atualiza) {
        this.$emit('atualiza-instrumento-analise-tecnica');
      }
      if (instrumentoEditado) {
        this.$emit('atualiza-instrumento-editado-contrato', instrumentoEditado);
      }
    },
    abrirModal() {
      this.$refs['modal-edicao-instrumento'].abrirModal();
    },
    fecharModal() {
      this.$refs['modal-edicao-instrumento'].fecharModal();
    },
  },
};
</script>
