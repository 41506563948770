var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-end"},[(
        !_vm.visualizacao &&
        _vm.buscarPermissao(_vm.permissoes.funcionalidade, _vm.permissoes.acaoComentar)
      )?_c('botao-padrao',{staticClass:"my-3",on:{"click":function($event){return _vm.abrirNovo(_vm.form.id, 'mensagem')}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.contrato.formulario.comentarios.novo_comentario'))+" ")]):_vm._e()],1),(_vm.tabela.dados?.length)?_c('v-data-table',{attrs:{"headers":_vm.tabela.colunas,"items":_vm.tabela.dados,"dense":"","hide-default-footer":"","show-expand":""},scopedSlots:_vm._u([{key:"item.acoes",fn:function({ item }){return [(!_vm.visualizacao)?_c('dropdown-padrao',{attrs:{"text":"","color":"secondary"},scopedSlots:_vm._u([{key:"botao",fn:function(){return [_c('v-icon',[_vm._v(" $dotsVertical ")])]},proxy:true}],null,true)},[_c('v-list-item-group',{staticStyle:{"overflow-y":"auto"}},[(
              _vm.buscarPermissao(
                _vm.permissoes.funcionalidade,
                _vm.permissoes.acaoResponde
              )
            )?_c('v-list-item',{staticClass:"d-flex min-height-drop-item",attrs:{"disabled":item.usuarioEncerramentoId != 'Aberto'},on:{"click":function($event){return _vm.abrirNovo(item.id, 'resposta')}}},[_c('v-icon',[_vm._v("$mdiReply")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.responder_comentario'))+" ")],1):_vm._e(),_c('v-divider'),(
              _vm.buscarPermissao(
                _vm.permissoes.funcionalidade,
                _vm.permissoes.acaoEncerrar
              )
            )?_c('v-list-item',{staticClass:"d-flex min-height-drop-item",attrs:{"disabled":item.usuarioEncerramentoId != 'Aberto'},on:{"click":function($event){return _vm.encerrarComentario(item.id)}}},[_c('v-icon',[_vm._v("$close")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.encerrar_comentario'))+" ")],1):_vm._e(),_c('v-divider'),(
              _vm.buscarPermissao(
                _vm.permissoes.funcionalidade,
                _vm.permissoes.acaoExcluir
              )
            )?_c('v-list-item',{staticClass:"d-flex min-height-drop-item",attrs:{"disabled":item.usuarioEncerramentoId != 'Aberto'},on:{"click":function($event){return _vm.excluir(item, 'mensagem')}}},[_c('v-icon',[_vm._v("$mdiTrashCanOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.excluir'))+" ")],1):_vm._e()],1)],1):_vm._e()]}},{key:"item.usuarioEncerramentoId",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{style:(item.usuarioEncerramentoId == 'Aberto'
              ? 'color: #027A48'
              : 'color: #B42318')},[_vm._v(" "+_vm._s(item.usuarioEncerramentoId)+" ")])])]}},{key:"expanded-item",fn:function({ item, headers }){return [(item.comentarios.length == 0)?_c('td',{staticStyle:{"text-align":"center","color":"#bdbdbd"},attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(_vm.$t('modulos.comentario.sem_comentario'))+" ")]):_vm._e(),_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{attrs:{"dense":"","striped":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.comentarios),function(comentario,index){return _c('tr',{key:comentario.id,style:('background:' + _vm.listrasTabela(index))},[_c('td',{staticClass:"pl-14"},[_c('div',[(!_vm.visualizacao)?_c('botao-padrao',{staticStyle:{"border":"none"},attrs:{"outlined":"","color":"secondary","border-none":true,"disabled":item.usuarioEncerramentoId != 'Aberto' || index != 0},on:{"click":function($event){return _vm.excluir(comentario, 'resposta')}}},[_c('v-icon',[_vm._v("$mdiTrashCanOutline")])],1):_vm._e()],1)]),_c('td',[_vm._v(_vm._s(comentario.usuarioCriacaoNome))]),_c('td',[_vm._v(_vm._s(comentario.mensagem))]),_c('td',[_vm._v(_vm._s(comentario.dataCriacao))])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.mensagem",fn:function({ item }){return [_c('td',{staticClass:"coluna-mensagem"},[_vm._v(" "+_vm._s(item.mensagem)+" ")])]}}],null,false,2264971933)}):_vm._e(),_c('modal',{ref:"modal",attrs:{"flagParticipanteConta":_vm.flagParticipanteConta},on:{"salvar-novo":_vm.salvarNovoComentario}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }