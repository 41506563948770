<template>
  <div>
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="
          !visualizacao &&
          buscarPermissao(permissoes.funcionalidade, permissoes.acaoComentar)
        "
        class="my-3"
        @click="abrirNovo(form.id, 'mensagem')"
      >
        {{ $t('modulos.contrato.formulario.comentarios.novo_comentario') }}
      </botao-padrao>
    </div>
    <v-data-table
      v-if="tabela.dados?.length"
      :headers="tabela.colunas"
      :items="tabela.dados"
      dense
      hide-default-footer
      show-expand
    >
      <!-- eslint-disable vue/valid-v-slot -->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao v-if="!visualizacao" text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <v-list-item-group style="overflow-y: auto">
            <v-list-item
              v-if="
                buscarPermissao(
                  permissoes.funcionalidade,
                  permissoes.acaoResponde
                )
              "
              class="d-flex min-height-drop-item"
              :disabled="item.usuarioEncerramentoId != 'Aberto'"
              @click="abrirNovo(item.id, 'resposta')"
            >
              <v-icon>$mdiReply</v-icon>
              {{ $t('geral.botoes.responder_comentario') }}
            </v-list-item>
            <v-divider />
            <v-list-item
              v-if="
                buscarPermissao(
                  permissoes.funcionalidade,
                  permissoes.acaoEncerrar
                )
              "
              class="d-flex min-height-drop-item"
              :disabled="item.usuarioEncerramentoId != 'Aberto'"
              @click="encerrarComentario(item.id)"
            >
              <v-icon>$close</v-icon>
              {{ $t('geral.botoes.encerrar_comentario') }}
            </v-list-item>
            <v-divider />
            <v-list-item
              v-if="
                buscarPermissao(
                  permissoes.funcionalidade,
                  permissoes.acaoExcluir
                )
              "
              :disabled="item.usuarioEncerramentoId != 'Aberto'"
              class="d-flex min-height-drop-item"
              @click="excluir(item, 'mensagem')"
            >
              <v-icon>$mdiTrashCanOutline</v-icon>
              {{ $t('geral.botoes.excluir') }}
            </v-list-item>
          </v-list-item-group>
        </dropdown-padrao>
      </template>

      <template v-slot:item.usuarioEncerramentoId="{ item }">
        <div class="d-flex align-center">
          <span
            :style="
              item.usuarioEncerramentoId == 'Aberto'
                ? 'color: #027A48'
                : 'color: #B42318'
            "
          >
            {{ item.usuarioEncerramentoId }}
          </span>
        </div>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td
          v-if="item.comentarios.length == 0"
          :colspan="headers.length"
          style="text-align: center; color: #bdbdbd"
        >
          {{ $t('modulos.comentario.sem_comentario') }}
        </td>
        <td :colspan="headers.length">
          <v-simple-table dense striped>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(comentario, index) in item.comentarios"
                  :key="comentario.id"
                  :style="'background:' + listrasTabela(index)"
                >
                  <td class="pl-14">
                    <div>
                      <botao-padrao
                        v-if="!visualizacao"
                        outlined
                        color="secondary"
                        style="border: none"
                        :border-none="true"
                        :disabled="
                          item.usuarioEncerramentoId != 'Aberto' || index != 0
                        "
                        @click="excluir(comentario, 'resposta')"
                      >
                        <v-icon>$mdiTrashCanOutline</v-icon>
                      </botao-padrao>
                    </div>
                  </td>
                  <td>{{ comentario.usuarioCriacaoNome }}</td>
                  <td>{{ comentario.mensagem }}</td>
                  <td>{{ comentario.dataCriacao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>

      <template v-slot:item.mensagem="{ item }">
        <td class="coluna-mensagem">
          {{ item.mensagem }}
        </td>
      </template>
    </v-data-table>
    <modal
      :flagParticipanteConta="flagParticipanteConta"
      ref="modal"
      @salvar-novo="salvarNovoComentario"
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import Modal from './modais/ComentarioModal.vue';
import ComentarioService from '@common/services/cadastros/ComentarioService';
export default {
  components: {
    Modal,
  },
  props: {
    form: {},
    permissoes: { type: Object, default: () => ({}) },
    origen: String,
    visualizacao: { type: Boolean, default: false },
  },
  data() {
    return {
      flagParticipanteConta: true,
      disabled: false,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            width: '40px',
          },
          {
            value: 'usuarioCriacaoNome',
            text: this.$t('modulos.comentario.tabela.usuario'),
          },
          {
            value: 'mensagem',
            text: this.$t('modulos.comentario.tabela.comentario'),
            sortable: true,
            width: '100px',
          },
          {
            value: 'dataCriacao',
            text: this.$t('modulos.comentario.tabela.data'),
            sortable: true,
          },
          {
            value: 'usuarioEncerramentoId',
            text: this.$t('modulos.comentario.tabela.situacao'),
            sortable: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.flagParticipanteConta =
      this.$store.getters['Autenticacao/participanteLogadoConta'];
    this.listarRegistros();
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    listarRegistros: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ComentarioService.buscar(this.form.id)
        .then((res) => {
          // comentários privados não devem ser exibidos para clientes(não participantes)
          if (!this.flagParticipanteConta) {
            res.data = res.data.filter((c) => !c.privado);
          }

          this.tabela.dados = res.data;
          this.tabela.dados.map((item) => {
            item.dataCriacao = helpers.formatarDataBr(item.dataCriacao, true);
            item.comentarios.map(
              (i) =>
                (i.dataCriacao = helpers.formatarDataBr(i.dataCriacao, true))
            );
            item.usuarioEncerramentoId =
              item.usuarioEncerramentoId != null
                ? this.$t('modulos.comentario.tabela.encerrado')
                : this.$t('modulos.comentario.tabela.aberto');
            return item;
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirNovo: function (id, tipo) {
      this.$refs['modal'].abrirModal(id, tipo);
    },
    salvarNovoComentario: function (form) {
      if (this.origen == 'contrato') form.contratoId = this.form.id;
      if (this.origen == 'orcamento') form.orcamentoId = this.form.id;
      if (this.origen == 'duvidaSolicitacaoParticipante')
        form.duvidaSolicitacaoParticipanteId = this.form.id;

      this.verificarSituacao(form);

      this.$store.dispatch('Layout/iniciarCarregamento');

      ComentarioService.salvar(form)
        .then((resposta) => {
          if (form.status) {
            this.encerrarComentario(
              form.comentarioProprietarioId
                ? form.comentarioProprietarioId
                : resposta.data
            );
          }
          this.toastSucesso(this.$t(`modulos.comentario.cadastro_sucesso`));
          this.listarRegistros();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluir: function (item) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ComentarioService.excluir(item.id)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.comentario.exclusao_sucesso`));
          this.listarRegistros();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listrasTabela: function (index) {
      return index % 2 !== 0 ? `white` : 'rgb(236, 239, 241)';
    },
    encerrarComentario: function (id) {
      if (id) {
        this.$store.dispatch('Layout/iniciarCarregamento');
        ComentarioService.encerrar(id)
          .then(() => {
            this.toastSucesso(this.$t(`modulos.comentario.encerrado_sucesso`));
            this.listarRegistros();
          })
          .catch((err) => {
            this.toastErro(
              this.$t(`geral.erros.${err.response.data.errors[0]}`)
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
    verificarSituacao: function (form) {
      if (form.status) {
        form.comentarioProprietarioId
          ? this.encerrarComentario(form.comentarioProprietarioId)
          : this.encerrarComentario(form.id);
      }
    },
  },
};
</script>

<style lang="scss">
.coluna-mensagem {
  max-width: 50vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 850px) {
  .coluna-mensagem {
    max-width: 25vw;
  }
}

@media only screen and (min-width: 851px) and (max-width: 1500px) {
  .coluna-mensagem {
    max-width: 40vw;
  }
}

.v-data-table__wrapper {
  overflow-x: hidden;
  line-height: normal !important;
}
</style>
