<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div />
      <div class="d-flex">
        <botao-padrao
          v-if="form.politicaPrecoPeca == 'Percentual'"
          class="mt-3 mr-2"
          :disabled="!tabela.selecionados.length"
          @click="abreModalAlterarPercentualDesconto"
        >
          {{ $t('modulos.contrato.alterar_percentual_desconto') }}
        </botao-padrao>
        <botao-padrao
          class="mt-3"
          @click="buscaPecas"
        >
          <v-icon>$mdiRefresh</v-icon>
          {{ $t('modulos.contrato.atualizar_dados') }}
        </botao-padrao>
      </div>
    </div>
    <tabela-padrao-prime-vue
      :key="keyAtualiza"
      v-model="tabela.selecionados"
      :dados="form.contratoPeca"
      class="mt-2"
      :colunas="tabela.colunas"
      filtro-geral
      paginacao-em-memoria
      sem-paginacao
      :filters="filters"
      ajustar-altura-linha
      ajustar-dropdown-acima
      :mostrar-acoes="false"
      :mostrar-seletor="false"
      :por-pagina="tabela.porPagina"
      :pagina-atual="tabela.paginaAtual"
      :global-filters="tabela.colunas.map(c => c.value)"
    />
    <AlterarPercentualDescontoPecaModal
      ref="modal-alterar-percentual-desconto-peca"
      @alterar-percentual="salvarAlteracaoPercentualDesconto"
    />
  </div>
</template>
<script>
// import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import AlterarPercentualDescontoPecaModal from './modais/AlterarPercentualDescontoPecaModal.vue';
import helpers from '@common/utils/helpers';
import { FilterMatchMode } from 'primevue/api';
export default {
  components: { AlterarPercentualDescontoPecaModal },
  props: {
    form: { type: Object, default: () => ({}) },
    instrumentoString: { type: String, default: '' },
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      keyAtualiza: 1,
      itensSelecionados: [],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [],
        porPagina: 10,
        paginaAtual: 1,
        colunasPercentual: [
          {
            value: 'itemCodigo',
            text: this.$t('modulos.contrato.tabela.codigo'),
            sortable: true,
            width: '10%',
          },
          {
            value: 'itemDescricao',
            text: this.$t('modulos.contrato.tabela.descricao'),
            sortable: true,
          },
          {
            value: 'tipoInstrumentoNome',
            text: this.$t('modulos.contrato.tabela.tipo_instrumento'),
            sortable: true,
          },
          {
            value: 'modeloInstrumentoNome',
            text: this.$t('modulos.contrato.tabela.modelo_instrumento'),
            sortable: true,
          },
          {
            value: 'valorVenda',
            text: this.$t('modulos.contrato.tabela.valor'),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
          {
            value: 'percentualDesconto',
            text: this.$t('modulos.contrato.tabela.percentual_desc'),
            sortable: true,
            formatter: (v) => {
              if (v) return `${v}%`;
              else return '0';
            },
          },
          {
            value: 'valorVendaComDesconto',
            text: this.$t('modulos.contrato.tabela.valor_com_desconto'),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
        colunasValor: [
          {
            value: 'itemCodigo',
            text: this.$t('modulos.contrato.tabela.codigo'),
            sortable: true,
            width: '10%',
          },
          {
            value: 'itemDescricao',
            text: this.$t('modulos.contrato.tabela.descricao'),
            sortable: true,
          },
          {
            value: 'tipoInstrumentoNome',
            text: this.$t('modulos.contrato.tabela.tipo_instrumento'),
            sortable: true,
          },
          {
            value: 'modeloInstrumentoNome',
            text: this.$t('modulos.contrato.tabela.modelo_instrumento'),
            sortable: true,
          },
          {
            value: 'valorVenda',
            text: this.$t('modulos.contrato.tabela.valor'),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
      },
    };
  },
  watch: {
    instrumentoString() {
      this.buscaPecas();
    },
    form: {
      handler() {
        this.defineColunasTabela();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.form.id) this.buscaPecas();
    this.defineColunasTabela();
  },
  methods: {
    defineColunasTabela() {
      if (this.form.politicaPrecoPeca == 'Valor')
        this.tabela.colunas = this.tabela.colunasValor;
      else this.tabela.colunas = this.tabela.colunasPercentual;

      ++this.keyAtualiza;
    },
    salvarAlteracaoPercentualDesconto(descontoPercentual) {
      if (descontoPercentual) {
        if (this.tabela.selecionados.length) {
          this.tabela.selecionados.map((item) => {
            this.form.contratoPeca.map((itemPeca) => {
              if (item.id === itemPeca.id) {
                item.percentualDesconto = descontoPercentual;
                itemPeca.percentualDesconto = descontoPercentual;
                item.valorVendaComDesconto =
                  itemPeca.valorVenda * (1 - descontoPercentual / 100);
                itemPeca.valorVendaComDesconto =
                  itemPeca.valorVenda * (1 - descontoPercentual / 100);
              }
            });
          });
        }
        this.keyAtualiza++;
      }
    },
    abreModalAlterarPercentualDesconto() {
      this.$refs['modal-alterar-percentual-desconto-peca'].abrirModal();
    },
    buscaPecas() {
      // if (!this.instrumentoString) return;
      // this.form.contratoPeca = [];
      // this.$store.dispatch('Layout/iniciarCarregamento');
      // InstrumentoService.listarTipoServicosManutencaoPecas(
      //   this.instrumentoString
      // )
      //   .then(async (res) => {
      //     if (res.data.peca.length) {
      //       res.data.peca.map((item) => {
      //         this.form.contratoPeca.push({
      //           tipoInstrumentoId: item.tipoInstrumentoId,
      //           modeloInstrumentoId: item.modeloInstrumentoId,
      //           itemId: item.itemId,
      //           id: item.itemId,
      //           percentualDesconto: 0,
      //           itemDescricao: item.itemDescricao,
      //           itemCodigo: item.itemCodigo,
      //           tipoInstrumentoNome: item.tipoInstrumentoNome,
      //           modeloInstrumentoNome: item.modeloInstrumentoNome,
      //           valorVenda: item.itemValor,
      //           valorVendaComDesconto: item.itemValor,
      //         });
      //       });
      //     }
      //   })
      //   .finally(() => {
      //     this.$store.dispatch('Layout/terminarCarregamento');
      //   });
    },
  },
};
</script>
