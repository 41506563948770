<template lang="">
  <modal-padrao
    ref="modal-clausula-contratual"
    :max-width="1000"
    :titulo="$t('modulos.contrato.formulario.clausula_contratual.associar_desassociar_clausula_contratual')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="aplicarSelecionados"
  >
    <div class="d-flex align-end">
      <div class="row">
        <input-text
          v-model="filtro.nome"
          :label="$t('modulos.contrato.formulario.clausula_contratual.nome')"
          class="col-12"
        />
      </div>
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      :selecionar-apenas-um="!multiplos"
      @paginar="listar"
    />
  </modal-padrao>
</template>
<script>
import ClausulaContratualService from '@common/services/cadastros/ClausulaContratualService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    form: { type: Object, default: () => ({})},
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'titulo',
            text: this.$t('modulos.contrato.formulario.clausula_contratual.nome'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      if(!this.form.tipoContrato) return;

      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        classificacao: this.form.tipoContrato,
      };
      ClausulaContratualService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
    },
    abrirModal(){
      // this.iniciarForm();
      this.$refs['modal-clausula-contratual'].abrirModal();
      this.listar();
    },
    // iniciarForm(){
    //   this.form = {
    //     clausulaContratualId: null,
    //     sequencia: 0,
    //     titulo: null,
    //     descricao: null
    //   }
    //   this.selecionados= []
    // },
    aplicarSelecionados: function () {
      this.selecionados = this.selecionados.map(item => item = {
          ...item,
          clausulaContratualId: item.id,
        })
      this.$refs['modal-clausula-contratual'].fecharModal();
      this.$emit('novoArquivo', this.selecionados);
      },
  },
};
</script>
