<template lang="">
  <modal-padrao
    ref="modal-steps-instrumento"
    :max-width="1000"
    :max-height="300"
    :titulo="
      $t(
        'modulos.contrato.formulario.itensContrato.steps.instrumentos_servico_calibracao'
      )
    "
    :ok-desabilitado="e1 !== 3"
    :mostra-botao-salvar="false"
  >
    <v-stepper
      v-model="e1"
      style="box-shadow: none"
    >
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step
          step="1"
          editable
        >
          {{
            $t('modulos.contrato.formulario.itensContrato.steps.instrumento')
          }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="2"
          editable
        >
          {{
            $t(
              'modulos.contrato.formulario.itensContrato.steps.servicos_calibracao'
            )
          }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="3"
          editable
        >
          {{
            $t('modulos.contrato.formulario.itensContrato.steps.confirmacao')
          }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div>
            <v-form ref="form">
              <div
                height="400px"
                width="100%"
              >
                <div class="row">
                  <input-select
                    v-model="tipoCalibracao"
                    class="col-12 col-md-4"
                    :label="$t('modulos.contrato.formulario.tipo_calibracao')"
                    :options="opcoes.tipoCalibracao"
                    obrigatorio
                  />
                  <input-select
                    v-model="localExecucao"
                    class="col-12 col-md-4"
                    :label="$t('modulos.contrato.formulario.local_execucao')"
                    :options="opcoes.localExecucao"
                    obrigatorio
                  />
                  <input-text
                    v-model="prazoEntrega"
                    type="number"
                    class="col-12 col-md-4"
                    :label="$t('modulos.contrato.formulario.prazo_entrega')"
                    obrigatorio
                  />
                  <div class="col-12">
                    <tabela-padrao-prime-vue
                      v-model="instrumentosSelecionados"
                      :dados="instrumentos"
                      class="mt-2"
                      :colunas="tabelaInstrumentos.colunas"
                      sem-paginacao
                      paginacao-em-memoria
                      ajustar-altura-linha
                      :mostrar-acoes="false"
                      ajustar-dropdown-acima
                      :por-pagina="tabelaInstrumentos.porPagina"
                      :pagina-atual="tabelaInstrumentos.paginaAtual"
                      filtro-geral
                      :filters="filters"
                      :global-filters="tabelaInstrumentos.colunas.map(c => c.value)"
                    > 
                      <template #header>
                        <botao-padrao
                          class="mr-2"
                          @click="abreModalAdicionaInstrumento"
                        >
                          {{
                            $t(
                              'modulos.ordem_servico.formulario.instrumento.novo_instrumento'
                            )
                          }}
                        </botao-padrao>
                      </template>
                      <template v-slot:faixa="{ slotProps }">
                        {{ slotProps.data?.quantidadeFaixas == 1 ? slotProps.data?.faixa?.descricao : slotProps.data?.quantidadeFaixas }}
                      </template>
                    </tabela-padrao-prime-vue>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </v-stepper-content>
        <v-stepper-content step="2">
          <div>
            <!-- Tree Editavel
            <pre>
              {{ dadosTreeEditavel }}
            </pre>
            Selecionados
            <pre>
              {{ servicosCalibracaoSelecionados }}
            </pre> -->
            <div>
              <Tree
                :selection-keys.sync="servicosCalibracaoSelecionados"
                :value="dadosTree"
                :expanded-keys="expandedKeys"
                selection-mode="checkbox"
                :selectable="true"
                @node-select="onNodeSelect"
                @node-unselect="onNodeUnselect"
              >
                <template #default="slotProps">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ `${slotProps.node.label}` }}
                        <b v-if="!slotProps.node.children?.length && slotProps.node.tipoInstrumentoNome"> (Instrumento sem serviços) </b>
                      </span>
                    </template>
                    <span>{{ (slotProps.node.tipoInstrumentoNome ? `Instrumento: ` : `Serviço: `) + slotProps.node.data }}</span>
                  </v-tooltip>
                </template>
              </Tree>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div>
            <div>
              <Tree
                :value="dadosTreeEditavel"
                :expanded-keys="expandedKeys"
              />
            </div>

            <!-- <div
              class="d-flex mt-4"
              style="justify-content: space-between"
            /> -->
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <criacao-instrumento-modal
      ref="modal-criacao-instrumento"
      @atualiza-listagem-instrumentos="atualizaListagemInstrumentos"
    />
    <template #botaoAdicional>
      <botao-padrao
        v-if="e1 > 1"
        color="secondary"
        class="mr-2"
        outlined
        @click="--e1"
      >
        {{
          $t('modulos.contrato.formulario.itensContrato.steps.voltar')
        }}
      </botao-padrao>

      <botao-padrao
        v-if="e1 < 3"
        color="primary"
        @click="() => ++e1"
      >
        {{
          $t('modulos.contrato.formulario.itensContrato.steps.proximo')
        }}
      </botao-padrao>

      <botao-padrao
        v-if="e1 == 3"
        color="primary"
        @click="confirmaStepsFormataObjetoDados()"
      >
        {{
          $t(
            'modulos.contrato.formulario.itensContrato.steps.confirmar'
          )
        }}
      </botao-padrao>
    </template>
  </modal-padrao>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import { FilterMatchMode } from 'primevue/api';
import ServicoService from '@common/services/cadastros/ServicoService';
import CriacaoInstrumentoModal from '@views/modulos/cadastros/contrato/components/modais/CriacaoInstrumentoModal';
import _ from 'lodash'
export default {
  components: { CriacaoInstrumentoModal },
  props: {
    opcoesTipoCalibracao: { type: Array, default: () => [] },
    opcoesLocalExecucao: { type: Array, default: () => [] },
    form: { type: Object, default: () => {} },
  },
  data() {
    return {
      e1: 1,
      sequencia: 1,
      valorkey: 1,
      valido: null,
      opcoes: {
        tipoCalibracao: [],
        localExecucao: [],
      },
      expandedKeys: {},
      dadosTreeEditavel: [],
      dadosTree: [],
      servicosCalibracaoSelecionados: [],
      tipoCalibracao: null,
      tipoCalibracaoNome: null,
      prazoEntrega: null,
      localExecucao: null,
      localExecucaoNome: null,
      listaTipoCalibracao: [
        { name: 'Rastreado', code: 'Rastreado' },
        { name: 'RBL', code: 'RBL' },
        { name: 'RBLE', code: 'RBLE' },
      ],
      instrumentos: [],
      instrumentosSelecionados: null,
      tabelaInstrumentos: {
        porPagina: 5,
        paginaAtual: 1,
        colunas: [
          { 
            value: 'codigoTag',
            text: this.$t('modulos.instrumento.tabela.codigo')
          },
          { 
            value: 'tipoInstrumento.nome', 
            text: this.$t('modulos.instrumento.tabela.tipo'), 
          },
          { 
           value: 'modeloInstrumento.nome',
           text: this.$t('modulos.instrumento.tabela.modelo') },
          {
            value: 'faixa',
            text: this.$t('modulos.instrumento.tabela.faixas'),
          },
        ]
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  watch: {
    e1(newValue, oldValue){
      if(oldValue == 1) this.validaFormularioInstrumentos();
      if(oldValue == 2 && newValue > 2) this.validaFormularioServicosCalibracao();
    },
    opcoesTipoCalibracao() {
      this.opcoes.tipoCalibracao = this.opcoesTipoCalibracao;
    },
    opcoesLocalExecucao() {
      this.opcoes.localExecucao = this.opcoesLocalExecucao;
    },
  },
  mounted() {},
  methods: {
    // verificarInstrumentoSemServico(node) {
    //   if(!node.children?.length && node.tipoInstrumentoNome) return ' (sem serviços disponíveis)'
    //   return ""
    // },
    async atualizaListagemInstrumentos(id) {
      await this.listarInstrumentos();
      this.instrumentos.map((item) => {
        if (item.id == id) {
          this.instrumentosSelecionados.push(item);
        }
      });
    },
    abreModalAdicionaInstrumento() {
      this.$refs['modal-criacao-instrumento'].abrirModal();
    },
    expandAll() {
      for (let node of this.dadosTree) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;

        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    onNodeSelect(event) {
      console.log(event)
      if (!event.children) {
        this.dadosTreeEditavel.map((item) => {
          if (item.key == event.instrumentoId) item.children.push(event);
        });
      } else {
        this.dadosTreeEditavel.map((item) => {
          if (item.key == event.key)
            item.children = JSON.parse(JSON.stringify(event.children));
        });
      }
    },
    onNodeUnselect(event) {
      if (!event.children) {
        this.dadosTreeEditavel.map((item) => {
          if (item.key == event.instrumentoId) {
            let indexServicoRemovido = null;
            item.children.map((item, index) => {
              if (item.key == event.key) indexServicoRemovido = index;
            });
            item.children.splice(indexServicoRemovido, 1);
          }
        });
      } else {
        this.dadosTreeEditavel.map((item) => {
          if (item.key == event.key) item.children = [];
        });
      }
    },
    buscaServicosCalibracaoInstrumentos() {
      let instrumentos = [];
      this.instrumentosSelecionados.forEach((item) => {
        instrumentos.push(item.id);
      });

      let params = {
        tipoCalibracaoId: this.tipoCalibracao,
        localExecucaoId: this.localExecucao,
        instrumentos: instrumentos,
      };

      this.$store.dispatch('Layout/iniciarCarregamento');
      ServicoService.pesquisaServicosCalibracaoInstrumentos(params)
        .then((res) => {
          this.dadosTree = _.cloneDeep(res.data);
          this.dadosTreeEditavel = _.cloneDeep(res.data);
          this.dadosTreeEditavel.map((item) => {
            item.children = [];
          });
          this.dadosTree.map((item) => {
            item.children.map((itemChildren) => {
              itemChildren.servicoId = itemChildren.key;
              itemChildren.key = this.valorkey++;
            });
          });
          this.expandAll();
          this.selecionarServicoCalibracaoAutomatico();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    selecionarServicoCalibracaoAutomatico: async function(){
      await this.$nextTick();
      if(!this.dadosTree?.some(() => true)) return;
      
      this.servicosCalibracaoSelecionados = {}

      this.dadosTree.forEach(instrumento => {
        instrumento.children.forEach(servico => {
          this.servicosCalibracaoSelecionados[servico.key] = { checked: true, partialChecked: false }
          this.servicosCalibracaoSelecionados[instrumento.key] = { checked: true, partialChecked: false }
          this.onNodeSelect(servico)
        })
      })
    },
    voltaStepValidacao: async function(step){
      await this.$nextTick();
      this.e1 = step
    },
    validaFormularioInstrumentos() {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (this.valido && this.instrumentosSelecionados.length) {
        this.e1 = 2;
        this.buscaServicosCalibracaoInstrumentos();
      } else {
        this.voltaStepValidacao(1);
        this.toastAlerta(
          this.$t(
            'modulos.contrato.erros.preencha_formulario_selecione_instrumento'
          )
        );
      }
    },
    validaFormularioServicosCalibracao() {
      let validaServicosCalibracaoSelecionados = true;
      this.dadosTreeEditavel.forEach((item) => {
        if (!item.children.length) validaServicosCalibracaoSelecionados = false;
      });
      if (validaServicosCalibracaoSelecionados) {
        this.e1 = 3;
      } else {
        this.voltaStepValidacao(2);
        this.toastAlerta(
          this.$t('modulos.contrato.erros.selecione_um_servico_instrumento')
        );
      }
    },
    listarInstrumentos: async function () {
      let parametros = {
        sort: 'Participante',
        ...this.filtroAplicado,
        participanteId: this.form.participanteId,
        flagExibeListaCompleta: true,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      await InstrumentoService.listar(parametros)
        .then((res) => {
          this.instrumentosSelecionados = [];
          this.instrumentos = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmaStepsFormataObjetoDados() {
      this.sequencia = 1;
      this.opcoesTipoCalibracao.forEach((item) => {
        if (item.value == this.tipoCalibracao)
          this.tipoCalibracaoNome = item.text;
      });
      this.opcoesLocalExecucao.forEach((item) => {
        if (item.value == this.localExecucao)
          this.localExecucaoNome = item.text;
      });
      this.dadosTreeEditavel.map((item) => {
        item.prazoEntrega = this.prazoEntrega;
        item.sequencia = this.sequencia++;
        item.numeroItem = this.sequencia;
        item.codigo = item.data;
        item.tipoCalibracaoId = this.tipoCalibracao;
        item.tipoCalibracaoNome = this.tipoCalibracaoNome;
        item.localExecucaoId = this.localExecucao;
        item.localExecucaoNome = this.localExecucaoNome;
        item.info = '';
        item.instrumentoId = item.key;
        // item.id = item.key;
        item.frequenciaCalibracaoEmMeses = item.frequenciaCalibracao;
        item.flagAdicionadoCliente = false;
        item.contratoInstrumentoServicoCalibracao = item.children;
      });
      this.dadosTreeEditavel.map((item) => {
        item.contratoInstrumentoServicoCalibracao.map((itemChildren) => {
          itemChildren.dataUltimaCalibracao = item.dataUltimaCalibracao;
          if (itemChildren.modoExibicao == 'Nome')
            itemChildren.servicoDescricao = itemChildren.label;
          else
            itemChildren.servicoDescricao =
              itemChildren.label + ' ' + itemChildren.descricao;
          itemChildren.servicoCodigo = itemChildren.data;

          itemChildren.quantidadeTotalCalibracao = null;
          itemChildren.valorUnitarioServico = itemChildren.valorServico;
          itemChildren.quantidadePontosCalibrarServico =
            itemChildren.minimoPontos;
          itemChildren.valorTotalPontoServico = null;
          itemChildren.valorUnitarioPontoAdicionalServico =
            itemChildren.valorPonto;
          itemChildren.quantidadeTotalPontosCalibrarServico = null;
          itemChildren.quantidadePontosCalibrarAdicionalServico = null;
          itemChildren.valorTotalPontoAdicionalServico = null;
          itemChildren.valorTotalCalibracao = null;

          itemChildren.flagRemovidoCliente = null;

          itemChildren.contratoInstrumentoServicoCalibracaoProximaCalibracao = [
            {
              dataProximaCalibracao: null,
            },
          ];
        });
      });

      this.dadosTreeEditavel.map((item) => {
        item.children = [];
      });
      this.$emit(
        'adiciona-instrumentos-servicos-calibracao',
        this.dadosTreeEditavel
      );
      this.$refs['modal-steps-instrumento'].fecharModal();
    },
    abrirModal() {
      this.$refs['modal-steps-instrumento'].abrirModal();
      this.listarInstrumentos();
      this.limparCampos();
    },
    limparCampos() {
      this.dadosTreeEditavel = [];
      this.dadosTree = [];
      this.instrumentosSelecionados = [];
      this.tipoCalibracao = null;
      this.localExecucao = null;
      this.prazoEntrega = null;
      this.e1 = 1;
      this.valido = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
  },
};
</script>
<style scoped>

:deep(.v-stepper__wrapper ) {
  overflow: visible !important;
}
</style>