<template>
  <modal-padrao
    ref="modal-alterar-percentual-desconto-peca"
    max-width="30%"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo="$t('modulos.contrato.alterar_percentual_desconto')"
    @ok="salvar"
  >
    <v-form ref="form" class="row">
      <input-money
        v-model="percentualDesconto"
        class="col-12 col-md-6"
        :label="$t('modulos.contrato.tabela.percentual_desc')"
        type="number"
        :options="optionsInputMoney"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '%',
        length: 11,
        precision: 2,
      },
      percentualDesconto: 0,
      valido: null,
    };
  },
  methods: {
    abrirModal: function () {
      this.$refs['modal-alterar-percentual-desconto-peca'].abrirModal();
    },
    salvar: function () {
      this.$emit('alterar-percentual', this.percentualDesconto);
      this.$refs['modal-alterar-percentual-desconto-peca'].fecharModal();
      this.percentualDesconto = 0;
    },
  },
};
</script>
