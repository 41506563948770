<template>
  <modal-padrao
    ref="modal-form-clausula-contratual"
    :max-width="1000"
    :titulo="$t('modulos.contrato.formulario.clausula_contratual.associar_desassociar_clausula_contratual')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :disabled="!valido"
    @ok="salvar"
  >
    <div>
      <div class="d-flex mb-8">
        <div class="titulo-pagina">
          {{ tituloFormulario }}
        </div>
      </div>

      <v-form
        ref="form"
        class="row"
      >
        <input-text
          v-model="form.titulo"
          class="col-12 col-md-9"
          :label="$t('modulos.clausula_contratual.formulario.titulo')"
          obrigatorio
          :max="150"
          trim
        />
        <input-select
          v-model="form.classificacao"
          :options="opcoes.classificacao"
          class="col-12 col-md-3"
          :label="$t('modulos.clausula_contratual.formulario.classificacao')"
          obrigatorio
        />
        <div class="col-12 col-md-12">
          <v-card
            class="v-card--flat px-0 mx-0"
            @contextmenu="show"
          >
            <input-html-editor
              v-model="form.descricao"
              desabilitar-align
              desabilitar-media
              class="col-12 col-md-12"
              :label="$t('modulos.clausula_contratual.formulario.descricao')"
              :obrigatorio="mensagemobrigatorio"
            />
          </v-card>
        </div>
        <v-menu
          v-model="showMenu"
          style="z-index: 99;"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
            >
              <v-list-item-title
                class="item-barra-superior"
                @click="addTag(item.text)"
              >
                {{ item.titulo }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-form>
    </div>
  </modal-padrao>
</template>
<script>
import { ClausulaContratualModel } from '@common/models/cadastros/ClausulaContratualModel';
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
import TagsContratoClausulaContratualService from '@common/services/cadastros/TagsContratoClausulaContratualService';
import { EnumTagsClasulaContratual } from '@common/models/geral/EnumTagsClasulaContratual';

export default {
  props: {},
  data() {
    return {
      valido: false,
      form: new ClausulaContratualModel({}),
      opcoes: {
        classificacao : [],
        tags: [],
      },
      mensagemobrigatorio: null,
      showMenu: false,
      x: 0,
      y: 0,
      items: [],
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.clausula_contratual.titulos.editar');
      return this.$t('modulos.clausula_contratual.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.clausula_contratual.validacoes.formulario_invalido');
    },
  },
  watch: {
    form: {
      handler() {
        if(!this.form.descricao?.includes("<p>")) this.mensagemobrigatorio = true
        if (this.$refs.form?.validate() && this.form.descricao?.includes("<p>")) this.valido = true;
      },
      deep: true,
    },
    'form.descricao': {
      handler(value) {
        if(value) {
          let regex = /^<p>\s*|\s*<\/p>$/g;
            let result = value.replace(regex, "");
          if(result != '') {
            this.mensagemobrigatorio = false
          }else{
            this.mensagemobrigatorio = true
            this.valido = false
          }
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.buscarClassificacao();
    this.listarTags();
  },
  methods: {
    show: function (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
    },
    addTag: function(value) {
      const regex = /^<p>\s*|\s*<\/p>$/g;
      this.form.descricao = this.form.descricao ? `<p>${this.form.descricao.replace(regex, '')} ${value}</p>` : `<p>${value}</p>`
    },
    listarTags: function() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TagsContratoClausulaContratualService.listar()
        .then((res) => {
          this.items = new EnumTagsClasulaContratual(res.data)
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    salvar: function () {
      this.$emit('novoArquivo', this.form);
      this.$refs['modal-form-clausula-contratual'].fecharModal();
    },
    cancelar: function () {
      this.$refs['modal-form-clausula-contratual'].fecharModal();
    },
    buscarClassificacao: function() {
      EnumeradorService.buscar('EnumTipoContrato').then((res) => {
        this.opcoes.classificacao = res
      });
    },
    abrirModal(item){
      this.iniciarForm(item)
      this.$refs['modal-form-clausula-contratual'].abrirModal();
    },
    iniciarForm(item) {
      this.form = {
        id: item.id,
        clausulaContratualId: item.clausulaContratualId,
        sequencia: item.sequencia,
        titulo: item.titulo,
        descricao: item.descricao,
        classificacao: item.classificacao
      }
    },
  },
};
</script>
