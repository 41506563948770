<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div />
      <div class="d-flex mt-4">
        <botao-padrao
          v-if="!$route.query.visualizacao"
          class="mt-3 mb-3"
          :disabled="!form.participanteId"
          :tooltip="tooltipAbrirModalInstrumentos"
          @click="abreModalStepsInstrumento"
        >
          {{
            $t('modulos.contrato.formulario.itensContrato.botoes.adicionar_novo_instrumento')
          }}
        </botao-padrao>
      </div>
    </div>
    <!-- <pre>
      
      {{ form.contratoInstrumento }}
    </pre> -->
    <data-table
      :key="keyAtualizaInstrumento"
      :value="form.contratoInstrumento"
      show-gridlines
      edit-mode="row"
      :editing-rows.sync="editingRows"
      :paginator="true"
      class="p-datatable-sm tabela-prime-vue-inherit"
      :expanded-rows.sync="expandedRows"
      :rows="50"
      striped-rows
      :reorderable-columns="true"
      data-key="sequencia"
      :row-hover="true"
      :selection.sync="instrumentosSelecionados"
      :filters.sync="filters"
      paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      :rows-per-page-options="[10, 25, 50]"
      current-page-report-template="({currentPage} de {totalPages})"
      :global-filter-fields="[
        'codigo',
        'sequencia',
        'tipoCalibracaoNome',
        'localExecucaoNome',
        'prazoEntrega',
      ]"
      responsive-layout="scroll"
      @row-reorder="onRowReorder"
      @row-edit-save="onRowEditSave"
    >
      <template #header>
        <div class="d-flex justify-end">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              class="p-inputtext-sm"
              style="height: 30px"
              placeholder="Filtrar"
            />
          </span>
        </div>
      </template>

      <Column
        :expander="true"
        :styles="{ width: '2%' }"
      />
      <Column
        v-if="!$route.query.visualizacao"
        field="acoes"
        header=""
        :styles="{ 'max-width': '50px' }"
      >
        <template #body="{ data }">
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <dropdown-padrao-item @click="abreModaEditarInstrumento(data.instrumentoId)">
              {{ $t('modulos.contrato.botoes.editar_instrumento') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item @click="abreModalCriterioAceitacao(data)">
              {{ $t('modulos.contrato.botoes.criterio_aceitacao') }}
            </dropdown-padrao-item>
          </dropdown-padrao>
        </template>
      </Column>
      <Column
        field="sequencia"
        header="Sequência"
        :styles="{ 'max-width': '6%' }"
      >
        <template #body="{ data }">
          <div class="d-flex">
            <icone-padrao
              :icone="
                definirIconeAdicionadoRemovido(data.flagAdicionadoCliente).icon
              "
              :classe-icone="
                definirIconeAdicionadoRemovido(data.flagAdicionadoCliente).color +
                  ' mr-2'
              "
              size="20"
              :tooltip="definirIconeAdicionadoRemovido(data.flagAdicionadoCliente).tooltip"
            />
            <span>
              {{ data.sequencia }}
            </span>
          </div>
        </template>
      </Column>
      <Column
        field="codigo"
        header="Instrumento"
        :styles="{ 'max-width': '15%' }"
      >
        <template #body="{ data }">
          <div class="d-flex">
            <botao-padrao
              text
              retira-padding-left=" pl-0"
              class="pl-0"
              color="secondary"
              :tooltip="'Editar Instrumento'"
              @click="abreModaEditarInstrumento(data.instrumentoId)"
            >
              <v-icon>$mdiTimerEdit</v-icon>
            </botao-padrao>
            <span style="align-self: center">
              {{ data.codigo }}
            </span>
          </div>
        </template>
      </Column>
      <Column
        field="faixa"
        header="Faixa"
        header-class="centraliza"
        :body-style="{ 'max-width': '6%', 'text-align': 'center' }"
      >
        <template #body="{data}">
          <span>
            {{ data.quantidadeFaixas > 1 ? `${data.quantidadeFaixas} Faixas` : data.faixa }}
          </span>
        </template>
      </Column>
      <Column
        field="frequenciaCalibracaoEmMeses"
        header="Frequência de Calib. Meses"
        header-class="centraliza"
        :body-style="{ 'max-width': '6%', 'text-align': 'center' }"
      />
      <Column
        field="tipoInstrumentoNome"
        header="Tipo Instrumento"
        header-class="centraliza"
      >
        <template #body="{data}">
          <span>
            {{ data.tipoInstrumento?.nome || data.tipoInstrumentoNome }}
          </span>
        </template>
      </Column>
      <Column
        field="tipoCalibracaoId"
        header="Tipo de Serviço"
        :styles="{ width: '15%' }"
      >
        <template #body="{ data }">
          <div>
            {{ data.tipoCalibracaoNome }}
          </div>
        </template>
      </Column>
      <Column
        field="localExecucaoId"
        header="Local de Execução"
        :styles="{ width: '15%' }"
      >
        <template #body="{ data }">
          <div>
            {{ data.localExecucaoNome }}
          </div>
        </template>
      </Column>
      <Column
        field="prazoEntrega"
        header="Prazo de Entrega"
        header-class="centraliza"
        :styles="{ width: '15%', 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <input-text 
            v-model="slotProps.data.prazoEntrega"
            sem-label
            flat
            solo
            type="number"
            class="flex-fill my-1 input-tabela-analise-tecnica"
            :disabled="$route.query.visualizacao"
          />
        </template>
      </Column>

      <Column
        v-if="!$route.query.visualizacao"
        field="acoes"
        :styles="{ 'max-width': '3%', 'text-align': 'center' }"
      >
        <template #body="{ index }">
          <botao-padrao
            text
            color="secondary"
            @click="excluir(index)"
          >
            <v-icon>$mdiTrashCanOutline</v-icon>
          </botao-padrao>
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :key="keyAtualiza"
            striped-rows
            class="p-datatable-sm tabela-prime-vue-inherit"
            :value="slotProps.data.contratoInstrumentoServicoCalibracao"
            edit-mode="row"
            :editing-rows.sync="editingRows"
            @row-edit-save="onRowSubTableEditSave($event, slotProps.index)"
          >
            <Column
              field="dataUltimaCalibracao"
              header="Data Última Calibração"
              :styles="{ width: '14%' }"
            >
              <template #body="slotPropsDataUltimaCalibracao">
                <input-date
                  v-model="
                    slotPropsDataUltimaCalibracao.data.dataUltimaCalibracao
                  "
                  class="input-date-tabela-contrato"
                  background-color="white"
                  :disabled="!form.dataInicio || $route.query.visualizacao"
                  @input="calculaCalibracoesEspecifica(
                    slotProps.data.sequencia,
                    slotPropsDataUltimaCalibracao.index,
                    slotPropsDataUltimaCalibracao.data.dataUltimaCalibracao)"
                />
              </template>
            </Column>
            <Column
              field="servicoCodigo"
              header="Codigo do Serviço"
            />
            <Column
              field="faixa"
              header="Faixa"
            />
            <Column
              field="quantidadeTotalCalibracao"
              header="Calibrações"
              header-class="centraliza"
              :styles="{ 'text-align': 'right' }"
            />
            <Column
              field="valorUnitarioServico"
              header="Valor do Serviço"
              header-class="centraliza"
              :styles="{ 'text-align': 'right', width: '10%' }"
            >
              <template #body="slotPropsValorUnitarioServico">
                <input-money
                  v-model="slotPropsValorUnitarioServico.data.valorUnitarioServico"
                  class="flex-fill my-1 input-tabela-analise-tecnica"
                  sem-label
                  hide-details
                  :disabled="$route.query.visualizacao"
                  :max="19"
                  @input="
                    recalculaValoresServicoAlteraValorServico(
                      slotProps.data.sequencia,
                      slotPropsValorUnitarioServico.index,
                      slotPropsValorUnitarioServico.data.valorUnitarioServico
                    )
                  "
                />
              </template>
            </Column>
            <Column
              field="valorUnitarioPontoAdicionalServico"
              header="Valor por Ponto"
              header-class="centraliza"
              :styles="{ 'text-align': 'right', width: '10%' }"
            >
              <template #body="slotPropsValorUnitarioPontoAdicionalServico">
                <input-money
                  v-model="slotPropsValorUnitarioPontoAdicionalServico.data.valorUnitarioPontoAdicionalServico "
                  class="flex-fill my-1 input-tabela-analise-tecnica"
                  sem-label
                  hide-details
                  :disabled="$route.query.visualizacao"
                  :max="19"
                  @input="
                    recalculaValoresServicoAlteraValorUnitarioPonto(
                      slotProps.data.sequencia,
                      slotPropsValorUnitarioPontoAdicionalServico.index,
                      slotPropsValorUnitarioPontoAdicionalServico.data
                        .valorUnitarioPontoAdicionalServico)"
                />
              </template>
            </Column>
            <Column
              field="quantidadePontosCalibrarServico"
              header="Pontos do Serviço"
              header-class="centraliza"
              :styles="{ 'text-align': 'right' }"
            />
            <Column
              field="quantidadePontosCalibrarAdicionalServico"
              header="Pontos Extras"
              header-class="centraliza"
              :styles="{ 'text-align': 'right' }"
            />
            <Column
              field="quantidadeTotalPontosCalibrarServico"
              header="Total de Pontos"
              header-class="centraliza"
              :styles="{ 'text-align': 'right' }"
            />
            <Column
              field="valorTotalCalibracao"
              header="Valor Total"
              header-class="centraliza"
              :styles="{ 'text-align': 'right' }"
            >
              <template #body="slotPropsValor">
                <div>
                  {{
                    helpers.formatarMoeda(slotPropsValor.data.valorTotalCalibracao)
                  }}
                </div>
              </template>
            </Column>
            <Column
              header="Info"
              field="info"
              header-class="centraliza"
              :styles="{ 'text-align': 'center' }"
            >
              <template #body="slotPropsInfo">
                <Button
                  style="
                    background-color: rgba(255, 255, 255, 0);
                    color: black;
                    border-color: rgba(255, 255, 255, 0);
                  "
                  type="button"
                  icon="pi pi-search"
                  aria:haspopup="true"
                  aria-controls="overlay_panel"
                  @click="toggle($event, slotPropsInfo.data)"
                />
              </template>
            </Column>
          </DataTable>

          <OverlayPanel
            id="overlay_panel"
            ref="op"
            append-to="body"
            style="width: 500px; height: 280px; overflow-y: scroll"
          >
            <DataTable
              :value="dadosOverlayPanel"
              striped-rows
              class="p-datatable-sm oculta-thead"
              :rows="4"
            >
              <Column
                field="coluna1"
                header=""
                :styles="{ width: '40%' }"
              >
                <template #body="slotPropsColuna">
                  <strong>
                    {{ slotPropsColuna.data.coluna1 }}
                  </strong>
                </template>
              </Column>
              <Column
                field="coluna2"
                header=""
              />
            </DataTable>
          </OverlayPanel>
        </div>
      </template>
    </data-table>
    <StepsInstrumentoCalibracaoModal
      ref="modal-steps-instrumento"
      :form="form"
      :opcoes-tipo-calibracao="opcoes.tipoCalibracao"
      :opcoes-local-execucao="opcoes.localExecucao"
      @adiciona-instrumentos-servicos-calibracao="
        adicionaInstrumentosServicosCalibracao
      "
    />
    <criterios-aceitacao-modal
      ref="modal-criterios-aceitacao"
    />
    <instrumento-edicao-modal
      ref="modal-edicao-instrumento"
      :instrumento-id="idInstrumentoEdicao"
      :atualiza="true"
      @atualiza-instrumento-editado-contrato="atualizaInstrumentoAnaliseTecnica"
    />
  </div>
</template>
<script>
import StepsInstrumentoCalibracaoModal from './modais/StepsInstrumentoCalibracaoModal.vue';
import UtilsService from '@common/services/UtilsService';
import ContratoService from '@common/services/cadastros/ContratoService';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import CriteriosAceitacaoModal from '../../operacao/components/modais/CriteriosAceitacaoModal';
import InstrumentoEdicaoModal from './modais/InstrumentoEdicaoModal.vue';
import helpers from '@common/utils/helpers';
import _ from 'lodash';
export default {
  components: {
    StepsInstrumentoCalibracaoModal,
    CriteriosAceitacaoModal,
    InstrumentoEdicaoModal,
  },
  props: {
    form: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      dadosOverlayPanel: null,
      helpers: helpers,
      columns: null,
      instrumentos: [],
      editingRows: [],
      expandedRows: [],

      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        codigo: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        sequencia: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        tipoCalibracaoNome: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        localExecucaoNome: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        prazoEntrega: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        criterioAceitacao: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },

      opcoesInstrumentoTipoCalibracao: [],
      flagLiberado: true,
      numeroContrato: null,
      keyAtualiza: 1,
      keyAtualizaInstrumento: 1,
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 2,
      },
      expanded: [],
      renumeracaoItens: 1,
      sequencia: 1,
      instrumentosSelecionadosIds: [],
      instrumentosSelecionados: [],
      tipoServicoManutencaoSelecionados: [],
      tipoServicoCalibracaoSelecionados: [],
      pecasSelecionadas: [],
      singleExpand: false,
      mostraInputs: false,
      showModalPesquisaInstrumentos: false,
      showModalTipoServicoManutencao: false,
      showModalTipoServicoCalibracao: false,
      showModalPecas: false,
      idInstrumentoEdicao: null,
      adicionadoPrimeiraDescricaoManutencao: false,
      adicionadoPrimeiraDescricaoCalibracao: false,
      calibracaoCampoOuLaboratorio: '',
      primeiroItemCalibracao: {},
      nomeServicoManutencao: '',
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [],
        indexAtualInstrumento: null,
        numeroItemContrato: 1,
        numeroItemTipoServicoManutencaoSelecionados: null,
        numeroItemTipoServicoCalibracaoSelecionados: null,
        numeroItemPecasSelecionadas: null,
        datasProximasCalibracoes: [],
      },
      opcoes: {
        tipoCalibracao: [],
        localExecucao: [],
      },
    };
  },
  computed: {
    existeContratoItemDetalhe: function () {
      let existeDetalhe = false;
      this.form.contratoInstrumento.map((item) => {
        if (item.contratoInstrumentoServicoCalibracao.length)
          existeDetalhe = true;
      });
      return existeDetalhe;
    },
    tooltipAbrirModalInstrumentos: function () {
      if (this.form.participanteId) return '';
      else return this.$t('geral.erros.participante_preencher');
    },
  },
  mounted() {
    this.sequencia = this.form.contratoInstrumento.length
      ? this.form.contratoInstrumento.length + 1
      : 1;
    this.listarTipoCalibracao();
    this.listarLocalExecucao();
  },
  methods: {
    atualizaInstrumentoAnaliseTecnica(instrumentoEditado) {
      this.form.contratoInstrumento.map((item) => {
        if (item.instrumentoId == instrumentoEditado.id) {
          item.codigo = instrumentoEditado.codigoTag;
          item.frequenciaCalibracaoEmMeses =
            instrumentoEditado.frequenciaCalibracaoEmMeses;
        }
      });

      this.keyAtualizaInstrumento++;
    },
    abreModaEditarInstrumento(instrumentoId) {
      this.$refs['modal-edicao-instrumento'].abrirModal();
      this.idInstrumentoEdicao = instrumentoId;
    },
    onRowReorder(event) {
      this.form.contratoInstrumento = event.value;

      this.renumeracaoItens = 1;
      this.form.contratoInstrumento.map((item) => {
        item.sequencia = this.renumeracaoItens++;
      });
    },
    adicionaInstrumentosServicosCalibracao(instrumentosServicos) {
      instrumentosServicos.map((item) => {
        // console.log(item)
        item.sequencia = this.sequencia++;
      });
      this.form.contratoInstrumento.push(...instrumentosServicos);
      let sequenciaInstrumentoInserido = this.form.contratoInstrumento.length;
      let lengthInstrumentoInserido = this.form.contratoInstrumento.length - 1;
      this.form.contratoInstrumento[
        lengthInstrumentoInserido
      ].contratoInstrumentoServicoCalibracao.forEach((item, index) => {
        if (item.dataUltimaCalibracao) {
          this.calculaCalibracoesEspecifica(
            sequenciaInstrumentoInserido,
            index,
            item.dataUltimaCalibracao
          );
        }
      });

      let instrumentosIds = this.form.contratoInstrumento.map(
        (item) => item.instrumentoId
      );
      let tipoInstrumentosIds = this.form.contratoInstrumento.map(
        (item) => item.tipoInstrumentoId
      );

      this.calculaValoresContrato();

      this.$emit(
        'adicionaServicosPecasInstrumento',
        instrumentosIds,
        tipoInstrumentosIds
      );
    },
    calculaCalibracoesEspecifica(
        sequencia,
        indexServico,
        dataUltimaCalibracao
    ) {
      this.form.contratoInstrumento.map((item) => {
        item.contratoInstrumentoServicoCalibracao.map((itemDetalhe, index) => {
          if (item.sequencia == sequencia && indexServico == index) {
            itemDetalhe.dataUltimaCalibracao = dataUltimaCalibracao;
            let params = {
              inicioContrato: this.form.dataInicio,
              numeroParcelas: this.form.numeroParcela ?? 1,
              ultimaCalibracao: dataUltimaCalibracao,
              frequenciaMeses: item.frequenciaCalibracaoEmMeses,
            };
            const verificarDadosParams = [];
            Object.entries(params).forEach((v) => {
              verificarDadosParams.push(!!v[1]);
            });

            if (!verificarDadosParams.every((v) => v)) return;
            this.$store.dispatch('Layout/iniciarCarregamento');
            ContratoService.buscarDatasProximasCalibracoes(params)
              .then((res) => {
                  itemDetalhe.quantidadeTotalCalibracao =
                    res.data.quantidadeCalibracoes;
                if(!res.data.quantidadeCalibracoes) this.toastAlerta(this.$t('modulos.contrato.validacoes.calibracoes_zerada'))
                if (res.data?.proximasCalibracoes.length) {
                  res.data?.proximasCalibracoes.map((itemProximaCalibracao) => {
                    itemDetalhe.contratoInstrumentoServicoCalibracaoProximaCalibracao =
                      [];
                    itemDetalhe.contratoInstrumentoServicoCalibracaoProximaCalibracao.push(
                      {
                        dataProximaCalibracao: `${itemProximaCalibracao}`,
                      }
                    );
                  });
                }

                this.calculaQuantidadeValoresServicosInstrumento(
                  sequencia,
                  indexServico
                );
              })
              .catch(() => {
                this.toastErro(
                  this.$t('modulos.contrato.erros.erro_buscar_datas_calibracao')
                );
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          }
        });
      });
    },
    calculaQuantidadeValoresServicosInstrumento(sequencia, indexServico) {
      this.form.contratoInstrumento.map((item) => {
        item.contratoInstrumentoServicoCalibracao.map((itemDetalhe, index) => {
          if (item.sequencia == sequencia && indexServico == index) {
            itemDetalhe.quantidadeTotalPontosCalibrarServico =
              item.quantidadeTotalPontos;

            itemDetalhe.quantidadePontosCalibrarAdicionalServico = ((itemDetalhe.quantidadeTotalPontosCalibrarServico - itemDetalhe.quantidadePontosCalibrarServico) || 0)

            if (itemDetalhe.quantidadePontosCalibrarAdicionalServico < 0)
              itemDetalhe.quantidadePontosCalibrarAdicionalServico = 0;

            itemDetalhe.valorTotalPontoAdicionalServico =
              itemDetalhe.valorUnitarioPontoAdicionalServico *
              itemDetalhe.quantidadePontosCalibrarAdicionalServico;

            itemDetalhe.valorTotalCalibracao =
              itemDetalhe.valorUnitarioServico +
              itemDetalhe.quantidadePontosCalibrarAdicionalServico *
                itemDetalhe.valorUnitarioPontoAdicionalServico *
                itemDetalhe.quantidadeTotalCalibracao;

            itemDetalhe.valorTotalPontoServico =
              itemDetalhe.valorTotalCalibracao *
              itemDetalhe.quantidadeTotalCalibracao;
          }
          this.keyAtualiza++;
        });
      });

      this.calculaValoresContrato();
    },
    recalculaValoresServicoAlteraValorServico: _.debounce(function (
      sequencia,
      indexServico,
      valorInserido
    ) {
      this.form.contratoInstrumento.map((item) => {
        item.contratoInstrumentoServicoCalibracao.map((itemDetalhe, index) => {
          if (item.sequencia == sequencia && indexServico == index) {
            itemDetalhe.valorUnitarioServico = Number(valorInserido);
            itemDetalhe.valorTotalCalibracao = (itemDetalhe.valorUnitarioServico * itemDetalhe.quantidadeTotalCalibracao)
              // itemDetalhe.quantidadePontosCalibrarAdicionalServico *
              //   itemDetalhe.valorUnitarioPontoAdicionalServico *
            itemDetalhe.valorTotalPontoServico =
              itemDetalhe.valorTotalCalibracao *
              itemDetalhe.quantidadeTotalCalibracao;
          }
        });
      });
      this.calculaValoresContrato();
      this.keyAtualiza++;
    },
    1000),
    recalculaValoresServicoAlteraValorUnitarioPonto: _.debounce(function (
      sequencia,
      indexServico,
      valorInserido
    ) {
      this.form.contratoInstrumento.map((item) => {
        item.contratoInstrumentoServicoCalibracao.map((itemDetalhe, index) => {
          if (item.sequencia == sequencia && indexServico == index) {
            itemDetalhe.valorUnitarioPontoAdicionalServico =
              Number(valorInserido);
            itemDetalhe.valorTotalCalibracao =
              itemDetalhe.valorUnitarioServico +
              itemDetalhe.quantidadePontosCalibrarAdicionalServico *
                Number(valorInserido) *
                itemDetalhe.quantidadeTotalCalibracao;

            itemDetalhe.valorTotalPontoAdicionalServico =
              itemDetalhe.valorUnitarioPontoAdicionalServico *
              itemDetalhe.quantidadePontosCalibrarAdicionalServico;

            itemDetalhe.valorTotalPontoServico =
              itemDetalhe.valorTotalCalibracao *
              itemDetalhe.quantidadeTotalCalibracao;
          }
        });
      });
      this.keyAtualiza++;
    },
    1000),
    calculaValoresContrato() {
      this.valorFinal = 0;
      this.form.contratoInstrumento.map((item) => {
        if (item.contratoInstrumentoServicoCalibracao?.length) {
          item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
            itemDetalhe.valorTotalCalibracao = (itemDetalhe.valorUnitarioServico * itemDetalhe.quantidadeTotalCalibracao)
            this.valorFinal += itemDetalhe.valorTotalCalibracao;
          });
        }
      });
      this.form.valorFinal = this.valorFinal;

      if (this.form.valorFinal && this.form.numeroParcela) {
        this.form.valorMensal = Number(
          this.form.valorFinal / this.form.numeroParcela
        ).toFixed(2);
      }
      this.valorItens = 0;
      this.form.contratoInstrumento.map((item) => {
        item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
          this.valorItens += itemDetalhe.valorUnitarioServico;
        });
      });

      if (this.valorItens)
        this.form.valorItens = Number(this.valorItens).toFixed(2);
    },
    toggle(event, info) {
      this.$refs.op.toggle(event);
      this.dadosOverlayPanel = [
        {
          coluna1: 'Quantidade Total:',
          coluna2: info.quantidadeTotalCalibracao,
        },
        {
          coluna1: 'Descrição Serviço:',
          coluna2: info.servicoDescricao,
        },
        {
          coluna1: 'Valor Total Ponto:',
          coluna2: this.helpers.formatarMoeda(info.valorTotalPontoServico),
        },
        {
          coluna1: 'Valor Original Serviço:',
          coluna2: this.helpers.formatarMoeda(info.valorServico),
        },
        {
          coluna1: 'Valor Total Ponto Adicional:',
          coluna2: this.helpers.formatarMoeda(
            info.valorTotalPontoAdicionalServico
          ),
        },
      ];
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      this.form.contratoInstrumento[index] = newData;
    },
    onRowSubTableEditSave(event, indexInstrumento) {
      let { newData, index } = event;
      this.form.contratoInstrumento[
        indexInstrumento
      ].contratoInstrumentoServicoCalibracao[index] = newData;
    },
    abreModalStepsInstrumento() {
      this.$refs['modal-steps-instrumento'].abrirModal();
    },

    listarTipoCalibracao: function () {
      TipoCalibracaoService.listar().then((res) => {
        this.opcoes.tipoCalibracao = new DropdownModel(res.data);
      });
    },
    listarLocalExecucao: function () {
      UtilsService.buscaLocalExecucao().then((res) => {
        this.opcoes.localExecucao = new DropdownModel(res.data);
      });
    },
    definirIconeAdicionadoRemovido: function(flag) {
      if (!flag)
        return {
          icon: '$successCircleOutline',
          color: 'primary-success-700--text',
          tooltip: this.$t('modulos.contrato.instrumento_validado')
        };
      return { icon: '$plusCircleOutline', color: 'warning--text', tooltip: this.$t('modulos.contrato.instrumento_adicionado') };
    },
    async validaUnicidadeInstrumento(instrumento) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.flagLiberado = true;
      if (instrumento.selected[0].id) {
        let params = {
          participanteId: this.form.participanteId,
          instrumentoId: instrumento.selected[0].id,
        };
        await ContratoService.validacaoUnicidadeInstrumento(params)
          .then((res) => {
            this.flagLiberado = res.data.flagLiberado;
            this.numeroContrato = res.data.numeroContrato;
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
    excluir(index) {
      this.sequencia = 1;
      this.expanded.splice(index, 1);
      this.form.contratoInstrumento.splice(index, 1);
      this.form.contratoInstrumento.map((item) => {
        item.sequencia = this.sequencia++;
      });
    },
    abreModalCriterioAceitacao(item) {
      if(!item?.instrumentoId) return;
      this.$refs['modal-criterios-aceitacao'].abrirModal(item.instrumentoId);
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.v-divider {
  max-width: 0% !important;
}

.remove-border-bottom td {
  border-bottom: unset !important;
}

.tabelaEscopoItens .input-padrao {
  padding: 0px;
  margin-bottom: 8px;
}

:deep(.centraliza > .p-column-header-content) {
  place-content: center;
}
:deep(.hover:hover) {
  border-color: black;
}

:deep(.v-input__slot) {
  font-size: 14px;
  min-height: 0 !important;
}

:deep(.v-label--externo) {
  margin-bottom: 0 !important;
}
:deep(.p-inputnumber) {
  height: 32px !important;
}

:deep(.input-date-tabela-contrato .v-input__append-inner) {
  margin-top: 0px !important;
  padding-top: 5px !important;
}
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.class-icone {
  cursor: pointer !important;
}
</style>
