<template>
  <div>
    <v-form ref="form">
      <div class="row mt-2">
        <input-cliente
          v-model="form.participante"
          class="col-12 col-md-4"
          :label="$t('modulos.contrato.formulario.cliente')"
          obrigatorio
          :disabled="$route.query.visualizacao"
          @ok="handleTrocaCliente"
        />
        <input-select
          v-model="form.participanteContatoId"
          class="col-12 col-md-4"
          :label="$t('modulos.contrato.formulario.contato')"
          :options="opcoes.contato"
          :disabled="$route.query.visualizacao"
          :loading="loadingContato"
        />
        <input-contrato
          v-model="form.contratoAnterior"
          class="col-12 col-md-2"
          :label="$t('modulos.contrato.formulario.renovacao_contrato')"
          :disabled="!form.id || $route.query.visualizacao"
        />
        <input-select
          v-model="form.status"
          class="col-12 col-md-2"
          :label="$t('modulos.contrato.formulario.situacao')"
          :options="opcoes.situacaoContrato"
          disabled
          obrigatorio
        />
      </div>
      <div class="row">
        <input-select
          v-model="form.tipoContrato"
          class="col-12 col-md-3"
          :label="$t('modulos.contrato.formulario.tipo_contrato')"
          :options="opcoes.tipoContrato"
          :disabled="$route.query.visualizacao"
          obrigatorio
        />
        <input-date
          v-model="form.dataInicio"
          class="col-12 col-md-2"
          :label="$t('modulos.contrato.formulario.data_inicio')"
          obrigatorio
          :disabled="$route.query.visualizacao"
        />
        <input-text
          v-model="form.numeroParcela"
          class="col-12 col-md-1"
          :label="$t('modulos.contrato.formulario.parcelas')"
          type="number"
          :disabled="form.tipoContrato === 'ContratoAberto' || $route.query.visualizacao"
          :obrigatorio="form.tipoContrato !== 'ContratoAberto'"
        />
        <input-date
          v-model="form.dataRenovacao"
          class="col-12 col-md-2"
          :label="$t('modulos.contrato.formulario.data_renovacao')"
          disabled
        />
        <input-date
          v-model="form.dataTermino"
          class="col-12 col-md-2"
          :label="$t('modulos.contrato.formulario.data_termino')"
          disabled
        />
        <input-date
          v-model="form.dataAssinatura"
          class="col-12 col-md-2"
          :label="$t('modulos.contrato.formulario.data_assinatura')"
          disabled
        />
      </div>
      <div class="row">
        <input-select
          v-model="form.tipoAcrescimo"
          class="col-12 col-md-3"
          :label="$t('modulos.contrato.formulario.tipo_acrescimo')"
          :options="opcoes.tipoDescontoAcrescimo"
          obrigatorio
          :disabled="$route.query.visualizacao"
        />
        <input-money
          v-model="form.valorAcrescimo"
          class="col-12 col-md-2 pb-0"
          :label="$t('modulos.contrato.formulario.acrescimo')"
          type="number"
          obrigatorio
          :disabled="$route.query.visualizacao"
          :options="optionsTipoAcrescimo"
        />
        <input-select
          v-model="form.tipoDesconto"
          class="col-12 col-md-3"
          :label="$t('modulos.contrato.formulario.tipo_desconto')"
          :options="opcoes.tipoDescontoAcrescimo"
          obrigatorio
          :disabled="$route.query.visualizacao"
        />
        <input-money
          v-model="form.valorDesconto"
          class="col-12 col-md-2 pb-0"
          :label="$t('modulos.contrato.formulario.desconto')"
          type="number"
          obrigatorio
          :disabled="$route.query.visualizacao"
          :options="optionsDesconto"
        />
        <input-select
          v-model="form.politicaPrecoPeca"
          class="col-12 col-md-2"
          :label="$t('modulos.contrato.formulario.politica_preco_peca')"
          :options="opcoes.tipoDescontoAcrescimo"
          obrigatorio
          :disabled="$route.query.visualizacao"
        />
      </div>
      <div class="row">
        <input-text
          v-model="form.nomeTestemunha"
          class="col-12 col-md-8"
          :label="$t('modulos.contrato.formulario.nome_testemunha')"
          obrigatorio
          :max="100"
          :disabled="$route.query.visualizacao"
        />
        <input-text
          v-model="form.cpfTestemunha"
          class="col-12 col-md-4"
          :label="$t('modulos.contrato.formulario.cpf_testemunha')"
          obrigatorio
          :mascara="mascaraCPF"
          :disabled="$route.query.visualizacao"
        />
      </div>
      <div class="row">
        <input-textarea
          v-model="form.observacao"
          class="col-12"
          :disabled="$route.query.visualizacao"
          :label="$t('modulos.contrato.formulario.observacao')"
        />
      </div>
      <div class="row">
        <input-money
          v-model="form.valorItens"
          class="col-12 col-md-4 pb-0"
          :label="$t('modulos.contrato.formulario.valor_itens')"
          type="number"
          :options="optionsInputMoney"
          disabled
        />
        <input-money
          :key="keyAtualizaValorFinal"
          v-model="form.valorFinal"
          class="col-12 col-md-4 pb-0"
          :label="$t('modulos.contrato.formulario.valor_final')"
          type="number"
          disabled
          :options="optionsInputMoney"
        />
        <input-money
          :key="keyAtualizaValorMensal"
          v-model="form.valorMensal"
          class="col-12 col-md-4 pb-0"
          :label="$t('modulos.contrato.formulario.valor_mensal')"
          type="number"
          disabled
          :options="optionsInputMoney"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import { InputContrato, InputCliente } from '@components/inputs';
export default {
  components: {
    InputContrato,
    InputCliente,
  },
  props: {
    form: {},
    prazoGarantia: { type: Number, default: null },
    primeiraVez: { type: Boolean, default: true },
  },
  data() {
    return {
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 2,
      },
      optionsTipoAcrescimo: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 4,
      },
      optionsDesconto: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 4,
      },
      mascaraCPF: '###.###.###-##',
      dadosRequisitosClienteFormatado: null,
      usuarioId: null,
      showModalColaboradorResponsavel: false,
      keyAtualizaValorFinal: 1,
      keyAtualizaValorMensal: 100,
      loadingContato: false,
      loadingAosCuidadosDe: false,
      loadingLocalExecucao: false,
      loadingCondicaoPagamento: false,
      valorAcrescimoCalculo: 0,
      valorDescontoCalculo: 0,
      opcoes: {
        situacaoContrato: helpers.StatusOrcamentoEnum,
        tipoContrato: helpers.TipoContratoEnum.sort(helpers.ordenarDropdown),
        tipoDescontoAcrescimo: helpers.TipoDescontoAcrescimoEnum,
        aosCuidadosDe: [],
        contato: [],
        localExecucao: [],
        condicaoPagamento: [],
        diasColeta: helpers.DiasColetaEnum,
        booleano: helpers.BoleanoEnum,
        formaSolicitacaoOrcamento: helpers.FormaSolicitacaoOrcamentoEnum,
      },
    };
  },
  watch: {
    'form.participante'(valor) {
      if (valor.value) {
        this.form.participanteId = valor.value;
        this.listaContatos(valor.value);
      } else if (this.form.participanteId) {
        this.listaContatos(this.form.participanteId);
      }
    },
    'form.contratoAnterior'(valor) {
      if (valor.value) this.form.contratoAnteriorId = valor.value;
    },
    'form.tipoAcrescimo'() {
      this.trocaTipoAcrescimo();
    },
    'form.tipoDesconto'() {
      this.trocaTipoDesconto();
    },
  },
  methods: {
    handleTrocaCliente(){
      this.form.participanteContatoId = null
      this.form.contratoInstrumento = []
    },
    reiniciaValoresFinalMensal() {
      this.somarItensCalibracao();
      this.form.valorFinal = this.form.valorItens;
      this.form.valorMensal = this.form.valorFinal / this.form.numeroParcela;

      this.form.valorFinal = Number(this.form.valorFinal).toFixed(2);
      this.form.valorMensal = Number(this.form.valorMensal).toFixed(2);

      this.keyAtualizaValorFinal++;
      this.keyAtualizaValorMensal++;
    },
    converteValoresParaNumber() {
      this.form.valorMensal = Number(this.form.valorMensal);
      this.form.valorDesconto = Number(this.form.valorDesconto);
      this.form.valorFinal = Number(this.form.valorFinal);
      this.form.valorItens = Number(this.form.valorItens);
      this.form.numeroParcela = Number(this.form.numeroParcela);
      this.form.valorAcrescimo = Number(this.form.valorAcrescimo);

      this.form.valorFinal = Number(this.form.valorFinal).toFixed(2);
      this.form.valorMensal = Number(this.form.valorMensal).toFixed(2);
    },
    trocaTipoAcrescimo() {
      if (this.form.tipoAcrescimo == 'Valor') {
        this.optionsTipoAcrescimo = {
          locale: 'pt-BR',
          prefix: '',
          suffix: '',
          length: 11,
          precision: 2,
        };
      } else {
        this.optionsTipoAcrescimo = {
          locale: 'pt-BR',
          prefix: '',
          suffix: '',
          length: 11,
          precision: 4,
        };
      }
    },
    trocaTipoDesconto() {
      if (this.form.tipoDesconto == 'Valor') {
        this.optionsDesconto = {
          locale: 'pt-BR',
          prefix: '',
          suffix: '',
          length: 11,
          precision: 2,
        };
      } else {
        this.optionsDesconto = {
          locale: 'pt-BR',
          prefix: '',
          suffix: '',
          length: 11,
          precision: 4,
        };
      }
    },
    listaContatos(id) {
      this.loadingContato = true;
      ParticipanteService.buscaParticipantesContatosPorId(id)
        .then((res) => {
          this.opcoes.contato = [];
          if (res?.data) {
            res?.data?.map((item) => {
              this.opcoes.contato.push({
                text: item.nome,
                value: item.id,
              });
            });
          } else this.opcoes.contato = [];
        })
        .finally(() => {
          this.loadingContato = false;
        });
    },
    somarItensCalibracao(){
      let valorItens = 0;
      this.form.contratoInstrumento?.forEach((item) => {
        item.contratoInstrumentoServicoCalibracao?.forEach((calibracao) => {
          valorItens += calibracao.valorTotalCalibracao;
        })
      })
      this.form.valorItens = valorItens;
    },
  },
};
</script>
<style>
.row + .row {
  margin-top: 0px;
}
</style>
